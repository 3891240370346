import { Position } from "reactflow"

interface ArrowProps {
    position: Position
}
const Arrow = ({ position }: ArrowProps) => {
    let styles = ""

    switch (position) {
        case Position.Left:
            styles = "left-[-16px]"
            break
        case Position.Top:
            styles = "top-[-16px] rotate-90 left-[44%]"
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className={`w-6 h-6 hover:font-bold absolute ${styles}`}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
        </svg>
    )
}

export default Arrow
