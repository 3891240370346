import { useEffect, useState } from "react"
import useAppSelector from "../../hooks/useAppSelector"
import {
} from "../../features/auth/authSlice"
import { UserData } from "../../types/auth"

import InvalidInput from "../../ui-components/auth/InvalidInput"

import ProgressBar from "../../ui-components/common/ProgressBar"

import useApi from "../../hooks/useApi"
import user from "../../api/user"
import ChangesSaved from "../../ui-components/common/ChangesSaved"


interface Props {}

export default function UserPage({}: Props) {

      const [unvalidProperties, setUnvalidProperties] = useState<string[]>([])
      const [errorMessage, setErrorMessage] = useState("")
      const [showSavePrompt, setShowSavePrompt] = useState(false);
      const [passwordObj, setPasswordObj] = useState<any>({
            oldPassword: "", newPassword: ""
      });
      const [userData, setUserData] = useState<UserData>({
          firstName: "",
          lastName: "",
          companyName: "",
          email: "",
          password: "",
      })
      const token = useAppSelector((state) => state.auth.token)
      const getAuthenticatedUserInfoApi = useApi(user.getAuthenticatedUserInfoApi)
      const userSettingUpdate = useApi(user.updateUser);
      const changePasswordAPI = useApi(user.changePassword);
      useEffect(() => {
          getAuthenticatedUserInfoApi.request(token)
      }, [])
      useEffect(()=>{
          if (getAuthenticatedUserInfoApi.data)
          {
            setUserData({
                  firstName: getAuthenticatedUserInfoApi?.data?.data.firstName,
                  lastName: getAuthenticatedUserInfoApi?.data?.data?.lastName,
                  email: getAuthenticatedUserInfoApi?.data?.data?.email,
                  password: "",
                  companyName: getAuthenticatedUserInfoApi?.data?.data?.companyName,
                  balance: getAuthenticatedUserInfoApi?.data?.data?.balance
            })
          }
          //console.log(getAuthenticatedUserInfoApi.data)
      },[getAuthenticatedUserInfoApi.data])

      const showHidePrompt = () => {
            setShowSavePrompt(true);
            setTimeout(()=>{setShowSavePrompt(false);},1000);
      }
      const saveChanges = (e: any) => {
            e.preventDefault()
            if (unvalidProperties.length == 0)
            {
                 
                  userSettingUpdate.request(token,userData);
                  showHidePrompt();
            }
            else
            {
                  console.log(unvalidProperties)
            }
      }
            // handlers
            const validPassword: RegExp = new RegExp(
                  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
              )
      const changePassword = (e: any) => {
            e.preventDefault();
            if (!validPassword.test(passwordObj.newPassword)) {
                  const errorMessage = "Invalid Password"
      
                  setUnvalidProperties(["password"])
                  return setErrorMessage(errorMessage)
            }
            
            changePasswordAPI.request(token, passwordObj)
      }

      useEffect(()=>{
            if (changePasswordAPI.error)
            {
                  setErrorMessage(changePasswordAPI?.error?.response?.data?.message)
                  console.log(changePasswordAPI?.error?.response?.data?.message)
            }
            else if (changePasswordAPI.data)
            {
                  setErrorMessage("")
                  showHidePrompt();
            }

      },[changePasswordAPI.data, changePasswordAPI.error])
      useEffect(()=>{
            console.log(userSettingUpdate.data)
      },[userSettingUpdate.data])
      const { isLoading, isError, isSuccess, message } = useAppSelector(
          (state) => state.auth
      )


  

      

        return (
            <>

                <div
                    className={`flex min-h-full flex-col justify-center py-2 sm:px-6 lg:px-8`}
                >
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                       
                        <div className="bg-white py-5 px-4 sm:rounded-lg sm:px-10">
                            {isLoading || isSuccess ? <ProgressBar /> : <></>}


                            <form
                                className="space-y-6 mb-6"
                                action="#"
                                method="POST"
                                onChange={() => {
                                    setErrorMessage("")
                                }}
                            >
                                <div>
                                    <label
                                        htmlFor="firstname"
                                        className="block text-sm font-medium text-gray-700"
                                    >

                                        First Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            required
                                            autoComplete="on"
                                            defaultValue={userData.firstName}
                                            onChange={(e) => {

                                                setUnvalidProperties((prev) =>
                                                    prev.filter(
                                                        (p) => p !== "firstname"
                                                    )
                                                )
                                                setUserData({
                                                    ...userData,
                                                    firstName: e.target.value,
                                                })
                                            }}
                                            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-300 focus:outline-none focus:ring-red-300 sm:text-sm`}
                                            style={{
                                                backgroundColor:
                                                    unvalidProperties.includes(
                                                        "firstname"
                                                    )
                                                        ? "#FEF2F2"
                                                        : "",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="lastname"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Last Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            required
                                            autoComplete="on"
                                            value={userData.lastName}
                                            onChange={(e) => {
                                                setUnvalidProperties((prev) =>
                                                    prev.filter(
                                                        (p) => p !== "lastname"
                                                    )
                                                )
                                                setUserData({
                                                    ...userData,
                                                    lastName: e.target.value,
                                                })
                                            }}
                                            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-300 focus:outline-none focus:ring-red-300 sm:text-sm`}
                                            style={{
                                                backgroundColor:
                                                    unvalidProperties.includes(
                                                        "lastname"
                                                    )
                                                        ? "#FEF2F2"
                                                        : "",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            disabled
                                            autoComplete="email"
                                            required
                                            value={userData.email}
                                            onChange={(e) => {
                                                setUnvalidProperties((prev) =>
                                                    prev.filter(
                                                        (p) => p !== "email"
                                                    )
                                                )
                                                setUserData({
                                                    ...userData,
                                                    email: e.target.value,
                                                })
                                            }}
                                            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-300 focus:outline-none focus:ring-red-300 sm:text-sm`}
                                            style={{
                                                backgroundColor:
                                                    unvalidProperties.includes(
                                                        "email"
                                                    )
                                                        ? "#FEF2F2"
                                                        : "",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="companyname"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Company Name (optional)
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="company_name"
                                            name="company_name"
                                            type="text"
                                            autoComplete="company_name"
                                            value={userData.companyName}
                                            onChange={(e) => {
                                                setUnvalidProperties((prev) =>
                                                    prev.filter(
                                                        (p) => p !== "company_name"
                                                    )
                                                )
                                                setUserData({
                                                    ...userData,
                                                    companyName: e.target.value,
                                                })
                                            }}
                                            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-300 focus:outline-none focus:ring-red-300 sm:text-sm`}
                                            style={{
                                                backgroundColor:
                                                    unvalidProperties.includes(
                                                        "company_name"
                                                    )
                                                        ? "#FEF2F2"
                                                        : "",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        onClick={saveChanges}
                                        className="flex w-full justify-center rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2 "
                                    >
                                        SAVE
                                    </button>
                                </div>
                            </form>
                            <div className="mt-4">
                                <InvalidInput content={errorMessage} />
                            </div>
                            {showSavePrompt && <div className="mt-4">
                                <ChangesSaved content="Changes Saved!" />
                            </div>}
                            <form className="space-y-6 mb-6"
                                action="#"
                                method="POST"
                                onChange={() => {
                                    setErrorMessage("")
                                }}>
                            <div>
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Old Password
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            value={passwordObj.oldPassword}
                                            onChange={(e) => {
                                                setUnvalidProperties((prev) =>
                                                    prev.filter(
                                                        (p) => p !== "password"
                                                    )
                                                )
                                                setPasswordObj({
                                                    ...passwordObj,
                                                    oldPassword: e.target.value,
                                                })
                                            }}
                                            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-300 focus:outline-none focus:ring-red-300 sm:text-sm`}
                                            style={{
                                                backgroundColor:
                                                    unvalidProperties.includes(
                                                        "password"
                                                    )
                                                        ? "#FEF2F2"
                                                        : "",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="passwordNew"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        New Password
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="passwordNew"
                                            name="passwordNew"
                                            type="password"
                                            required
                                            value={passwordObj.newPassword}
                                            onChange={(e) => {
                                                setUnvalidProperties((prev) =>
                                                    prev.filter(
                                                        (p) => p !== "passwords"
                                                    )
                                                )
                                                setPasswordObj({
                                                      ...passwordObj,
                                                      newPassword: e.target.value
                                                })
                                            }}
                                            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-300 focus:outline-none focus:ring-red-300 sm:text-sm`}
                                            style={{
                                                backgroundColor:
                                                    unvalidProperties.includes(
                                                        "passwords"
                                                    )
                                                        ? "#FEF2F2"
                                                        : "",
                                            }}
                                        />
                                    </div>
                                </div>
    
                                <div>
                                    <button
                                        type="submit"
                                        onClick={changePassword}
                                        className="flex w-full justify-center rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2 "
                                    >
                                        CHANGE PASSWORD
                                    </button>
                                </div>
                            </form>
    

                        </div>
                    </div>
                </div>
            </>
        )
      
}