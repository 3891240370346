import React, { useState, PropsWithChildren } from "react"

import { Outlet } from "react-router-dom"
import SidebarComponent from "../common/SidebarComponent"
import NavbarComponent from "../common/NavbarComponent"
import DescriptionInputModel from "../modal/DescriptionInputModal"
import { useSelector } from "react-redux"
import useAppSelector from "../../hooks/useAppSelector"

const MemoizedSidebarComponent = React.memo(SidebarComponent)
const MemoizedNavbarComponent = React.memo(NavbarComponent)

// ==============================|| MAIN LAYOUT ||============================== //

interface MainLayoutProps extends PropsWithChildren {}
export default function MainLayout({ children }: MainLayoutProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const data = useAppSelector((state)=> state.promptDescReducer);
    return (
        <>
            <div>
                <MemoizedSidebarComponent
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
                {data.show && <DescriptionInputModel />}
                <div className="lg:pl-72">
                    <MemoizedNavbarComponent
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main className="">
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    )
}
