import React, { useState, useEffect } from "react"
import {
    DocumentChartBarIcon,
    DocumentPlusIcon,
} from "@heroicons/react/24/outline"
import { Node } from "reactflow"
import Checkmark from "./Checkmark"
import Papa from "papaparse"

interface PredictiveInputProps {
    selectedNode: Node<any>
    setSelectedNode: any
    setDisabled: any
    handlePredictiveUpload: (
        referenceFileData: CSVData,
        fileToPredictData: CSVData
    ) => void
}

interface CSVData {
    fileName: string
    fileType: string
    fileData: string
    sheetName: string
}

const formatCSV = (csvString: string) => {
    // Parse CSV string using PapaParse
    const parsedData = Papa.parse(csvString, {
        header: true,
        skipEmptyLines: true,
    })

    // Extract headers and data
    const headers = parsedData.meta?.fields
    const data = parsedData?.data
    if (!headers || !data) {
        return ""
    }

    // Format data as a CSV string
    const formattedCSV = [headers.join(",")]
    data.forEach((row: any) => {
        const rowValues = headers.map((header) => row[header])
        formattedCSV.push(rowValues.join(","))
    })

    return formattedCSV.join("\n")
}

export default function ({
    selectedNode,
    setDisabled,
    setSelectedNode,
    handlePredictiveUpload,
}: PredictiveInputProps) {
    const [sourceCsvData, setSourceCsvData] = useState<CSVData | null>(
        selectedNode.data.referenceFile
    )
    useEffect(()=>{
        return ()=> {
            setDisabled(false);
        }
    },[])
    const [targetCsvData, setTargetCsvData] = useState<CSVData | null>(
        selectedNode.data.fileToPredict
    )

    const [showCheckmark, setShowCheckmark] = useState(false)

    const handleSourceFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = (event) => {
                if (event.target) {
                    setSourceCsvData({
                        fileName: file.name,
                        fileType: file.type,
                        fileData: formatCSV(event.target.result as string),
                        sheetName: ''
                    })
                }
            }
            reader.readAsText(file)
        }
    }

    const handleTargetFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = (event) => {
                if (event.target) {
                    setTargetCsvData({
                        fileName: file.name,
                        fileType: file.type,
                        fileData: formatCSV(event.target.result as string),
                        sheetName: ''
                    })
                }
            }
            reader.readAsText(file)
        }
    }

    const handleUpload = () => {
        if (sourceCsvData && targetCsvData) {
            handlePredictiveUpload(sourceCsvData, targetCsvData)
            setShowCheckmark(true)
            setTimeout(() => {
                setShowCheckmark(false)
                setSelectedNode(null)
            }, 600)
        }
    }

    return (
        <div className="h-[100%]" onClick={()=>{setDisabled(true)}}>
            {showCheckmark ? (
                <div className="h-[100%] w[100%] flex justify-center items-center">
                    <Checkmark />
                </div>
            ) : (
                <>
                    <header className="flex items-center justify-center gap-2 my-2">
                        <DocumentChartBarIcon className="h-8 w-8" />
                        <h1 className="font-bold text-lg">
                            Predictive Classification
                        </h1>
                    </header>
                    <div>
                        <div className="flex justify-between py-2">
                            {!!sourceCsvData?.fileName && (
                                <div className="border border-gray-200 w-[50%]">
                                    <p className="text-md text-center text-white bg-green-500">
                                        Reference File
                                    </p>
                                    <div className="p-1">
                                        <p className="text-sm">
                                            File Name:{" "}
                                            <p className="font-semibold">
                                                {sourceCsvData.fileName
                                                    .slice(0, 13)
                                                    .toUpperCase()}
                                                {sourceCsvData.fileName.length >
                                                    13 && "..."}
                                            </p>
                                        </p>
                                        <p className="text-sm">
                                            File Type:{" "}
                                            <p className="font-semibold">
                                                {sourceCsvData?.fileType?.toUpperCase()}
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            )}

                            {!!targetCsvData?.fileName && (
                                <div className="border border-gray-200 w-[50%]">
                                    <p className="text-md text-center text-white bg-red-500">
                                        File To Predict
                                    </p>
                                    <div className="p-1">
                                        <p className="text-sm">
                                            File Name:{" "}
                                            <p className="font-semibold">
                                                {targetCsvData.fileName
                                                    .slice(0, 13)
                                                    .toUpperCase()}
                                                {targetCsvData.fileName.length >
                                                    13 && "..."}
                                            </p>
                                        </p>
                                        <p className="text-sm">
                                            File Type:{" "}
                                            <p className="font-bold">
                                                {targetCsvData?.fileType?.toUpperCase()}
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="mt-1">
                            <label
                                htmlFor="csvFile"
                                className="block text-sm leading-6 text-gray-900"
                            >
                                {!sourceCsvData?.fileName ? "Upload" : "Update"}{" "}
                                the reference file:
                            </label>
                            <div className="bg-gray-50">
                                <input
                                    type="file"
                                    id="csvFile"
                                    accept=".csv"
                                    className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 text-xs sm:leading-6 "
                                    onChange={handleSourceFileChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="mt-1">
                            <label
                                htmlFor="csvFile"
                                className="block text-sm leading-6 text-gray-900"
                            >
                                {!targetCsvData?.fileName ? "Upload" : "Update"}{" "}
                                the file to predict
                            </label>
                            <div className="bg-gray-50">
                                <input
                                    type="file"
                                    id="csvFile"
                                    accept=".csv"
                                    className="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:leading-6 text-xs"
                                    onChange={handleTargetFileChange}
                                />
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={handleUpload}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 left-4 cursor-pointer"
                        disabled={!sourceCsvData || !targetCsvData}
                    >
                        SAVE
                    </button>
                    <button
                        onClick={()=>{setSelectedNode(null)}}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 right-4"
                    >
                        CANCEL
                    </button>
                </>
            )}
        </div>
    )
}
