// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-menu {
      background: white;
      border-style: solid;
      box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
      position: absolute;
      z-index: 10;
}

.context-menu button {
      border: none;
      display: block;
      padding: 0.5em;
      text-align: left;
      width: 100%;
}

.context-menu button:hover {
      background: white;
}`, "",{"version":3,"sources":["webpack://./src/views/canvas/ContextMenu.css"],"names":[],"mappings":"AAAA;MACM,iBAAiB;MACjB,mBAAmB;MACnB,6CAA6C;MAC7C,kBAAkB;MAClB,WAAW;AACjB;;AAEA;MACM,YAAY;MACZ,cAAc;MACd,cAAc;MACd,gBAAgB;MAChB,WAAW;AACjB;;AAEA;MACM,iBAAiB;AACvB","sourcesContent":[".context-menu {\n      background: white;\n      border-style: solid;\n      box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);\n      position: absolute;\n      z-index: 10;\n}\n\n.context-menu button {\n      border: none;\n      display: block;\n      padding: 0.5em;\n      text-align: left;\n      width: 100%;\n}\n\n.context-menu button:hover {\n      background: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
