import React from "react";
import {
  HashRouter,
  Route
} from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import Routes from "./routes";

function App() {
  return (<Routes />);
}

export default App;



