import { useState, useCallback, useEffect, useRef, useMemo } from "react"
import CanvasHeader from "./CanvasHeader"
import Cookies from "js-cookie"
import ReactFlow, {
    XYPosition,
    useReactFlow,
    ReactFlowInstance,
    addEdge,
    Background,
    Controls,
    applyNodeChanges,
    applyEdgeChanges,
    FitViewOptions,
    DefaultEdgeOptions,
    OnNodesChange,
    OnEdgesChange,
    OnConnect,
    NodeTypes,
    Edge,
    useStore,
    Node,
    ReactFlowJsonObject,
    Viewport,
} from "reactflow"
import "reactflow/dist/style.css"
import PromptNode from "./customNodes/PromptNode"
import DocumentNode from "./customNodes/DocumentNode"
import DatabaseNode from "./customNodes/DatabaseNode"
import CanvasSidebar from "./CanvasSidebar"
import CanvasPreview from "./CanvasPreview"
import CanvasNodeInput from "./CanvasNodeInput"
import { ProcessFlowType } from "../../types/flow"
import useApi from "../../hooks/useApi"
import predict from "../../api/predict"
import flow from "../../api/flow"
import useAppSelector from "../../hooks/useAppSelector"
import PredictiveNode from "./customNodes/PredictiveNode"
import { useDrop } from "react-dnd"
import { ItemTypes } from "../../utils/ItemTypes"
import ContextMenu from "./ContextMenu"
import { generateRandomId } from "../../utils/misc"
import {
    setPage,
    setPredictSuccess,
    setProcess,
} from "../../features/flowSlice"
import { useDispatch } from "react-redux"
import {
    checkForGoogleSheet,
    makeNodeSelected,
    predictAPIPayloadManipulate,
    updateBalance,
    validateProcessFlow,
} from "./utils/misc"
import user from "../../api/user"
import { setUserData } from "../../features/auth/authSlice"
import { UserData } from "../../types/auth"
import { Snackbar } from "@mui/material"
import { Bounce, toast } from "react-toastify"

interface CanvasProps {}

interface ErrorStatusType {
    status: boolean
    message: string
}

const fitViewOptions: FitViewOptions = {
    padding: 0.5,
}

const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: false,
}

const nodeTypes: NodeTypes = {
    prompt: PromptNode,
    document: DocumentNode,
    predictive: PredictiveNode,
    database: DatabaseNode,
}

export default function Canvas({}: CanvasProps) {
    const { addNodes } = useReactFlow()
    const dispatch = useDispatch()
    const zoomLevel = useStore((store) => store.transform[2])
    const viewPortX = useStore((store) => store.transform[0])
    const viewPortY = useStore((store) => store.transform[1])
    const zoomLevelRef = useRef<number>(zoomLevel)
    const viewPortXRef = useRef<number>(viewPortX)
    const viewPortYRef = useRef<number>(viewPortY)
    const processChange = useRef<boolean>(false)
    const chatflowIdCurrent = useRef<string>("")
    const token = useAppSelector((state) => state.auth.token)
    const mousePosRef = useRef<XYPosition>({ x: 0, y: 0 })
    const [mPos, setMPos] = useState({ x: 0, y: 0 })
    const [fitView, setFitView] = useState(false)
    const reactFlowWrapper = useRef<HTMLDivElement | null>(null)
    const [previewLoading, setPreviewLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const userData = useAppSelector((state) => state.auth.userData)

    const floatingDivStyle: React.CSSProperties = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 30,
        background: "rgba(0, 0, 0, 0.1)", // Set the background color and alpha for transparency
    }
    const updateFlowAPI = useApi(flow.updateFlow)
    const createFlowAPI = useApi(flow.createFlow)
    const saveFlowHandler = () => {
        if (!flowInfo.id) {
            createFlowAPI.request(token, flowInfo)
        } else {
            updateFlowAPI.request(token, flowInfo)
        }
    }
    const [reactFlowInstance, setReactFlowInstance] =
        useState<ReactFlowInstance | null>(null)
    // const userData = window.localStorage.getItem("userData")

    const [flowInfo, setFlowInfo] = useState<ProcessFlowType>({
        id: "",
        tempId: "",
        name: "",
        description: "",
        data: null,
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
        userId: "",
        isSaved: false,
        lastAccessDate: new Date().toDateString(),
        userTokens: userData?.balance || 0,
    })
    function initBeforeUnLoad(showExitPrompt: boolean) {
        window.onbeforeunload = (event) => {
            // Show prompt based on state
            if (showExitPrompt) {
                const e = event || window.event
                e.preventDefault()
                if (e) {
                    e.returnValue = ""
                }
                return ""
            }
        }
    }

    let [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.BOX,
        drop: (item, monitor) => {
            const delta = monitor.getClientOffset() //monitor.getDifferenceFromInitialOffset();
            const newDt: any = delta
            newDt.zoomLevel = zoomLevelRef.current
            newDt.viewPortX = viewPortXRef.current
            newDt.viewPortY = viewPortYRef.current

            return {
                item,
                monitor: newDt,
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        //hover: (item, monitor) => {console.log(item);console.log(monitor); return item}
    }))

    const [isOpen, setIsOpen] = useState(false)
    const [previewError, setPreviewError] = useState<string>("")
    const [processValidationError, setProcessValidationError] =
        useState<ErrorStatusType>({
            status: false,
            message: "",
        })
    const [nodes, setNodes] = useState<Node[]>([])
    const [edges, setEdges] = useState<Edge[]>([])

    const [viewport, setViewport] = useState<Viewport>({
        x: 0,
        y: 0,
        zoom: 1,
    })

    const URLpath = document.location.href.toString().split("/")
    const URLpath2 = document.location.pathname
    let chatflowId: string =
        URLpath[URLpath.length - 1] === "canvas"
            ? ""
            : URLpath[URLpath.length - 1]

    const [previewData, setPreviewData] = useState<any[]>([])
    const predictAPI = useApi(predict.predictAPI)
    const flowApi = useApi(flow.getOneFlow)

    const onNodesChange: OnNodesChange = useCallback(
        (changes) => {
            setNodes((nds) => applyNodeChanges(changes, nds))
        },
        [setNodes]
    )
    const onEdgesChange: OnEdgesChange = useCallback(
        (changes) => {
            setEdges((eds) => applyEdgeChanges(changes, eds))
        },
        [setEdges]
    )
    const onConnect: OnConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    )
    let [tempNode, setTempNode] = useState<any>(null)
    const handleButtonClicks = (e: any) => {
        const key = e.key
        if (disabled) return
        if (e.ctrlKey && e.key === "c") {
            console.log("Ctrl+C pressed")

            for (const n of nodes) {
                if (n.selected) {
                    setTempNode(n)
                }
            }
            // Add your Ctrl+C logic here
        }
        if (e.ctrlKey && e.key === "v") {
            console.log("Ctrl+V pressed")
            if (tempNode == null) return

            const position = {
                x: tempNode.position.x + 50 + Math.random(),
                y: tempNode.position.y + 50 + Math.random(),
            }

            addNodes({
                ...tempNode,
                id: generateRandomId(16),
                position,
                selected: false,
            })
            // Add your Ctrl+C logic here
        }
        const nodeTemp: any[] = []

        nodes.map((e) => {
            if (
                e.selected &&
                (e.data.isEditing == false || e.data.isEditing == undefined)
            )
                nodeTemp.push(e)
        })

        if (key === "Delete" || key === "Backspace") {
            const tempEdges: Edge[] = []
            edges.map((e) => {
                let tempEdge = JSON.parse(JSON.stringify(e))
                for (const n of nodeTemp) {
                    if (n.id == e.target || n.id == e.source) {
                        tempEdge.selected = true
                    }
                }
                tempEdges.push(tempEdge)
            })
            setEdges((eds) =>
                tempEdges.filter(
                    (nd) => nd.selected == undefined || nd.selected == false
                )
            )

            setNodes((nds) =>
                nds.filter(
                    (nd) => nd.selected == false || nd.data?.isEditing === true
                )
            )
        }
    }
    useEffect(() => {
        if (createFlowAPI.data) {
            //flowInfo = createFlowAPI.data.data;
            //flowInfo.userId = createFlowAPI.data.data.id;
            setFlowInfo(createFlowAPI.data.data)
            dispatch(setProcess({ process: createFlowAPI.data.data }))
            handleRun(false)
        }
    }, [createFlowAPI.data, createFlowAPI.error])

    const handleRun = async (previewMode: boolean) => {
        if (flowInfo.data && token && userData) {
            const validationError = validateProcessFlow(flowInfo)
            setProcessValidationError({
                status: validationError,
                message: "Error: Process not complete!",
            })
            const {notSignedIn, hasSheet} = await checkForGoogleSheet(
                flowInfo,
                token,
                userData.gsheet_access_token || ""
            )
            setProcessValidationError({
                status: notSignedIn,
                message:
                    "Token Expired, please connect google account to proceed",
            })
            if (notSignedIn) {
                setUserData({ ...userData, gsheet_access_token: undefined  })
            }
            if (validationError || notSignedIn) {
                return
            }

            const filteredNodes = flowInfo.data.nodes.filter((nd) => {
                return edges.some(
                    (ed) => ed.source === nd.id || ed.target === nd.id
                )
            })
            const flowInfoAfterFilterUnconnectedNodes: ProcessFlowType = {
                ...flowInfo,
                data: {
                    ...flowInfo.data,
                    nodes: filteredNodes,
                },
            }
            //console.log(flowInfoAfterFilterUnconnectedNodes)
            if (flowInfoAfterFilterUnconnectedNodes.id === "") {
                saveFlowHandler()
            } else {
                if (!userData?.balance || userData?.balance <= 0) {
                    setProcessValidationError({
                        status: true,
                        message: "🦄 No token left!",
                    })
                } else {
                    const filterFlow = makeNodeSelected(
                        flowInfoAfterFilterUnconnectedNodes,
                        menu?.id
                    )
                    userData.balance &&
                        predictAPI.request(
                            {...(predictAPIPayloadManipulate(
                                filterFlow,
                                previewMode,
                                userData.balance
                            ) as object)},
                            
                            
                        )
                }

                setIsOpen(true)
            }
        }
    }

    const updateNodeArrowPositions = () => {
        //console.log("edges", edges)
        setNodes((prev) => {
            return prev.map((nd) => {
                if (nd.type === "prompt") {
                    const eds = edges.filter((ed) => ed.target === nd.id)
                    const arrowPositions: string[] = []
                    eds?.forEach((ed: Edge) => {
                        if (
                            ed.targetHandle &&
                            !arrowPositions.includes(ed.targetHandle)
                        ) {
                            arrowPositions.push(ed.targetHandle)
                        }
                    })
                    return {
                        ...nd,
                        data: {
                            ...nd.data,
                            arrowPositions,
                        },
                    }
                } else {
                    return nd
                }
            })
        })
    }
    const updateOrderPrompts = () => {
        let firstNode: Node<any> | null = null

        for (const node of nodes) {
            const connectedToPromptOnLeft = edges.some((ed) => {
                const sourceNode = nodes.find((nd) => nd.id === ed.source)
                return ed.target === node.id && sourceNode?.type === "prompt"
            })

            if (
                node.type === "prompt" && // is prompt
                !!edges.some(
                    (ed) => ed.source === node.id || ed.target === node.id
                ) && // has any edge connected to it
                !connectedToPromptOnLeft
                // source of the node is not connected to prompt
            ) {
                firstNode = node
            }
        }

        if (!firstNode) return false

        let current: Node | null = firstNode
        const isConnectedAsSource: (node: Node) => boolean = (node: Node) =>
            edges.some((ed) => ed.source === node.id) // Changed .includes to .some

        let i: number = 0
        let iterate: boolean = !!firstNode
        const nodesCopy = [...nodes] // Make a copy of nodes to avoid mutating the original

        while (iterate) {
            i++
            nodesCopy.forEach((nd, index) => {
                if (nd.id === current?.id) {
                    nodesCopy[index] = {
                        ...nd,
                        data: {
                            ...nd.data,
                            order: i,
                        },
                    }
                }
            })
            // Find the next target node
            const nextEdge = edges.find((ed) => ed.source === current?.id)

            if (nextEdge) {
                const nextNodeId = nextEdge.target
                current = nodes.find((nd) => nd.id === nextNodeId) || null // Handle possible undefined
            } else {
                iterate = false
            }
        }

        return nodesCopy
    }

    useEffect(() => {
        const flowData: ReactFlowJsonObject = {
            nodes,
            edges,
            viewport,
        }
        setFlowInfo((prev) => {
            return {
                ...prev,
                data: flowData,
            }
        })
        dispatch(setProcess({ process: { ...flowInfo, data: flowData } }))

        initBeforeUnLoad(true)
        processChange.current = true
    }, [nodes, edges])

    useEffect(() => {
        const response = updateOrderPrompts()

        if (!response) {
            setNodes((prev) => {
                return prev.map((nd) => {
                    if (nd.type === "prompt")
                        return {
                            ...nd,
                            data: {
                                ...nd.data,
                                order: null,
                            },
                        }
                    else return nd
                })
            })
        } else {
            setNodes(response)
        }
        // update Arrow positions
        updateNodeArrowPositions()
    }, [edges])

    const checkforNoError = (data: any) => {
        let error = false
        for (const d of data?.data) {
            if (d.status === "error") {
                error = true
                setProcessValidationError({ status: true, message: d.message })

                break
            }
        }
        if (error === false) dispatch(setPredictSuccess(true))
        else dispatch(setPredictSuccess(false))
        return error
    }
    useEffect(() => {
        if (predictAPI.data && predictAPI.data?.data != "") {
            setPreviewError("")
            if (checkforNoError(predictAPI.data)) {
            } else {
                console.log(predictAPI.data)
                setPreviewData(predictAPI.data.data)
                dispatch(setPage(2))
                let balance = 0
                if (userData) balance = updateBalance(predictAPI.data, userData)
                if (token) user.updateBalanceAPI(token, balance)
                const tempUserData = JSON.parse(JSON.stringify(userData))
                tempUserData.balance = balance
                dispatch(setUserData(tempUserData))
            }
        }
        if (predictAPI.data?.data == "") {
            setProcessValidationError({
                status: true,
                message: predictAPI?.data?.message || "Something went wrong!",
            })
        }
    }, [predictAPI.data])
    useEffect(() => {
        if (predictAPI.loading) {
            setPreviewLoading(true)
        } else {
            setPreviewLoading(false)
        }
    }, [predictAPI.loading])
    useEffect(() => {
        if (predictAPI.error) {
            console.log("--Predict api error")
            console.log(predictAPI)
            setPreviewError(
                "Error while generating output, please check your flow!"
            )
        } else {
            setPreviewError("")
        }
    }, [predictAPI.error])

    useEffect(() => {
        const pr = "" //Cookies.get('process-id') || "";

        if (chatflowId || pr !== "") {
            chatflowIdCurrent.current = chatflowId
            if (pr === "") Cookies.set("process-id", chatflowId || "")
            if (chatflowId === "") chatflowId = pr || ""
            flowApi.request(token, chatflowId)

            setFitView(true)
        }
        setTimeout(() => {
            processChange.current = false
            window.onbeforeunload = null
        }, 1500)
        return () => {
            // Your cleanup logic here
            console.log("Component unmounted")
            window.onbeforeunload = null
            // Call your function here or perform any cleanup you need
        }
    }, [])
    useEffect(() => {
        zoomLevelRef.current = zoomLevel
        viewPortXRef.current = viewPortX
        viewPortYRef.current = viewPortY
    }, [zoomLevel, viewPortX, viewPortY])
    useEffect(() => {
        //console.log(mPos);
    }, [mPos])
    useEffect(() => {
        if (flowApi.data) {
            const flowInfo = flowApi.data.data
            setFlowInfo(flowInfo)
            dispatch(setProcess({ process: flowInfo }))
            setNodes(flowInfo.data?.nodes || [])
            setEdges(flowInfo.data?.edges || [])
        }

        window.onbeforeunload = null
    }, [flowApi.data, flowApi.error])

    const onMouseMove = (event: MouseEvent) => {
        const bounds = reactFlowWrapper.current!.getBoundingClientRect()
        mousePosRef.current = {
            x: event.clientX - bounds.left,
            y: event.clientY - bounds.top,
        }
        //const { x, y } = project.screenToFlowPosition({ x: mousePosRef.current.x, y: mousePosRef.current.y });
        // console.log({x,y});
        //const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

        // translate mouse position to graph position
        const reactFlowBounds =
            reactFlowWrapper.current!.getBoundingClientRect()

        const position = reactFlowInstance?.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        })
        // console.log(position)
        //setMPos({x: mousePosRef.current.x, y: mousePosRef.current.y})
        //setMousePos([mousePosRef.current[0]]);
        //console.log(mousePosRef.current)
    }

    const [menu, setMenu] = useState<any>(null)
    const refPan = useRef<HTMLDivElement>(null)
    const onPaneClick = useCallback(() => setMenu(null), [setMenu])
    const onNodeContextMenu = useCallback(
        (event: React.MouseEvent, node: Node) => {
            // Prevent native context menu from showing
            event.preventDefault()

            // Calculate position of the context menu. We want to make sure it
            // doesn't get positioned off-screen.
            const pane = refPan.current?.getBoundingClientRect()
            if (pane) {
                console.log(node)
                setMenu({
                    id: node.id,
                    type: "node",
                    nodeType: node.type,
                    processFlow: flowInfo,
                    top:
                        event.clientY < pane.height - 200
                            ? event.clientY
                            : undefined,
                    left:
                        event.clientX < pane.width - 200
                            ? event.clientX
                            : undefined,
                    right:
                        event.clientX >= pane.width - 200
                            ? pane.width - event.clientX
                            : undefined,
                    bottom:
                        event.clientY >= pane.height - 200
                            ? pane.height - event.clientY
                            : undefined,
                })
            }
        },
        [setMenu]
    )

    const onEdgeContextMenu = useCallback(
        (event: React.MouseEvent, edge: Edge) => {
            // Prevent native context menu from showing
            event.preventDefault()

            // Calculate position of the context menu. We want to make sure it
            // doesn't get positioned off-screen.
            const pane = refPan.current?.getBoundingClientRect()
            if (pane) {
                setMenu({
                    id: edge.id,
                    type: "edge",
                    top:
                        event.clientY < pane.height - 200
                            ? event.clientY
                            : undefined,
                    left:
                        event.clientX < pane.width - 200
                            ? event.clientX
                            : undefined,
                    right:
                        event.clientX >= pane.width - 200
                            ? pane.width - event.clientX
                            : undefined,
                    bottom:
                        event.clientY >= pane.height - 200
                            ? pane.height - event.clientY
                            : undefined,
                })
            }
        },
        [setMenu]
    )

    return (
        <div ref={refPan} onKeyDown={handleButtonClicks} tabIndex={0}>
            <Snackbar
                open={processValidationError.status}
                autoHideDuration={5000}
                onClose={() => {
                    setProcessValidationError({ status: false, message: "" })
                }}
                message={processValidationError.message}
            />
            <CanvasHeader
                processFlow={flowInfo}
                setProcessFlow={setFlowInfo}
                isLoading={flowApi.loading}
                setNodes={setNodes}
                handleRun={handleRun}
                processChange={processChange}
                chatflowIdCurrent={chatflowIdCurrent}
            />
            <CanvasSidebar nodes={nodes} setNodes={setNodes} edges={edges} />
            <CanvasNodeInput
                setDisabled={setDisabled}
                nodes={nodes}
                setNodes={setNodes}
                edges={edges}
                setEdges={setEdges}
            />
            {
                <div
                    ref={reactFlowWrapper}
                    className="h-[100vh] w-[100vw]"
                    onMouseMove={(event) => onMouseMove(event.nativeEvent)}
                >
                    {disabled && <div style={floatingDivStyle}></div>}
                    <ReactFlow
                        onInit={setReactFlowInstance}
                        nodes={nodes}
                        edges={edges}
                        onNodeContextMenu={onNodeContextMenu}
                        onEdgeContextMenu={onEdgeContextMenu}
                        onPaneClick={onPaneClick}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        fitView={fitView}
                        defaultViewport={viewport}
                        edgesUpdatable={!disabled}
                        edgesFocusable={!disabled}
                        nodesDraggable={!disabled}
                        nodesConnectable={!disabled}
                        nodesFocusable={!disabled}
                        draggable={!disabled}
                        panOnDrag={!disabled}
                        elementsSelectable={!disabled}
                        fitViewOptions={fitViewOptions}
                        defaultEdgeOptions={defaultEdgeOptions}
                        nodeTypes={nodeTypes}
                        className="bg-gray-100"
                        proOptions={{ hideAttribution: true }}
                        ref={drop}
                    >
                        <Controls
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                transform: "translate(-50%, -50%)",
                                top: "64px",
                            }}
                            position="top-center"
                        />
                        <Background color="rgb(230 230 230)" gap={10} />
                        {menu && (
                            <ContextMenu
                                handleRun={handleRun}
                                onClick={onPaneClick}
                                {...menu}
                            />
                        )}
                    </ReactFlow>
                </div>
            }
            <CanvasPreview
                previewData={previewData}
                loading={previewLoading}
                isOpen={isOpen}
                processFlow={flowInfo}
                setIsOpen={setIsOpen}
                previewError={previewError}
            />
        </div>
    )
}
