import { createSlice } from "@reduxjs/toolkit";
import { ProcessFlowType } from "../types/flow";
import { Node } from "reactflow";

interface AllProcessState {
      allProcess: ProcessFlowType[] | null
      process: ProcessFlowType | null,
      selectedNode: Node | null,
      predictSuccess: boolean,
      page: number,
      showDownloadDialog: boolean,
  }
  
  const initialState: AllProcessState = {
      allProcess : null,
      process : null,
      selectedNode: null,
      page: 2,
      predictSuccess: false,
      showDownloadDialog: false,
  }

export const processSlice = createSlice({
      name: "process",
      initialState,
      reducers: {
            setAllProcess: (state, action) => {
                  state.allProcess = action.payload.allProcess
            },
            setPage: (state, action) => {
                  console.log(action.payload);
                  state.page = action.payload
            },
            setShowDownloadDialog: (state, action) => {
                  state.showDownloadDialog = action.payload
            },
            setPredictSuccess: (state, action) => {
                  state.predictSuccess = action.payload
            },
            resetAllProcess: (state) => {
                  state.allProcess = null
            },
            setProcess: (state, action) => {
                  state.process = action.payload.process
            },
            resetProcess: (state) => {
                  state.process = null
            },
            setSelectedNode: (state, action)=>{
                  state.selectedNode= action.payload
            },
            resetSelectedNode: (state)=>{
                  state.selectedNode = null
            },
            
            
      }
});


// Action creators are generated for each case reducer function
export const {setPage, setShowDownloadDialog, setPredictSuccess, setAllProcess, resetAllProcess, setProcess, resetProcess, setSelectedNode, resetSelectedNode} = processSlice.actions;

export default processSlice.reducer;
