import { useEffect, useState } from "react"
import { Node, NodeProps, Handle, Position, useReactFlow } from "reactflow"
import NodeCard from "../../../ui-components/canvas/node/NodeCard"

const DocumentIcon = (
    <svg
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <linearGradient
            id="a"
            gradientTransform="matrix(1 0 0 -1 0 64)"
            gradientUnits="userSpaceOnUse"
            x2="0"
            y1="61"
            y2="3"
        >
            <stop offset="0" stopColor="#26c281" />
            <stop offset="1" stopColor="#3fc380" />
        </linearGradient>
        <linearGradient
            id="b"
            gradientTransform="matrix(1 0 0 -1 0 64)"
            gradientUnits="userSpaceOnUse"
            x2="0"
            y1="61"
            y2="47"
        >
            <stop offset="0" stopColor="#036b53" />
            <stop offset="1" stopColor="#036b53" />
        </linearGradient>
        <linearGradient
            id="c"
            gradientUnits="userSpaceOnUse"
            x1="40"
            x2="54"
            y1="17"
            y2="31"
        >
            <stop offset="0" stopColor="#383e51" />
            <stop offset="1" stopColor="#655c6f" stopOpacity="0" />
        </linearGradient>
        <path d="m10 61v-58h30l14 14v44h-14z" fill="url(#a)" />
        <g transform="scale(1 -1)">
            <path d="m10-4h30v1h-30z" fill="#fff" fillOpacity=".412" />
            <path d="m10-61h44v1h-44z" fill="#2e3132" fillOpacity=".294" />
        </g>
        <g fillRule="evenodd">
            <path d="m54 17-14-14v14z" fill="url(#b)" />
            <path d="m40 17 14 14v-14z" fill="url(#c)" opacity=".2" />
        </g>
        <path
            d="m21 22v22h14v-1h-7v-3h7v-1h-7v-3h15v-14zm1 1h20v4h-20zm0 5h5v3h-5zm6 0h8v3h-8zm9 0h5v3h-5zm-15 4h5v3h-5zm6 0h8v3h-8zm9 0h5v3h-5zm-15 4h5v3h-5zm18 1c-1.108 0-2 .892-2 2 0 1.068.831 1.927 1.883 1.988.077.366.116.738.117 1.111-.002.647-.118 1.289-.344 1.896 1.467-1.028 2.341-2.705 2.344-4.496v-.5c0-1.108-.892-2-2-2m-18 3h5v3h-5z"
            fill="#036b53"
        />
    </svg>
)

type NodeData = {
    fileName: string | null
    fileType: string | null
    fileData: string | null
}

type DocumentNode = Node<NodeData>

export default function DocumentNode({ data, selected }: NodeProps<NodeData>) {
    return (
        <NodeCard
            title={data.fileType?.toLowerCase() as string}
            description={data.fileName || "Select a file"}
            icon={DocumentIcon}
            selected={selected}
        >
            <Handle
                type="source"
                id="abc"
                position={Position.Right}
                isConnectable={true}
            />
        </NodeCard>
    )
}
