import Cookies from "js-cookie"
import React, { useState, useEffect, Fragment } from "react"
import {
    ArrowLeftCircleIcon,
    ChevronDownIcon,
    ClipboardIcon,
    CogIcon,
} from "@heroicons/react/24/outline"
import ProgressBar from "../../ui-components/common/ProgressBar"
import { useNavigate } from "react-router-dom"
import "./CanvasHeader.css"
//import logo from "../../assets/images/logo.png"
// import LogoImage from "../../assets/imageJS/logo"
import logo from "../../assets/images/newlogo.png"
import SuccessAlert from "../../ui-components/canvas/input/SuccessAlert"
import { ProcessFlowType } from "../../types/flow"
import useApi from "../../hooks/useApi"
import flow from "../../api/flow"
import useAppSelector from "../../hooks/useAppSelector"
import APIResponse from "../../types/response"
import { Edge, Node } from "reactflow"
import PreviewImage from "../../assets/imageJS/PreviewImage"
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar"
import { useDispatch } from "react-redux"
import { checkIfPromptSelected } from "./utils/misc"
import { Menu, Transition } from "@headlessui/react"
interface CanvasHeaderProps {
    processFlow: ProcessFlowType
    setProcessFlow: any
    isLoading: boolean
    handleRun: any
    processChange: any
    chatflowIdCurrent: any
    setNodes: any
    ///setProcessChange: any
}

export default function CanvasHeader({
    processFlow,
    setProcessFlow,
    isLoading,
    handleRun,
    chatflowIdCurrent,
    setNodes,
    //setProcessChange,
    processChange,
}: CanvasHeaderProps) {
    const navigate = useNavigate()
    const updateFlowAPI = useApi(flow.updateFlow)
    const createFlowAPI = useApi(flow.createFlow)
    const token = useAppSelector((state) => state.auth.token)
    const selectedNode = useAppSelector(
        (state) => state.processReducer.selectedNode
    )
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(" ")
    }
    const saveFlowHandler = (isSaved: boolean) => {
        const tempProcessFlow = JSON.parse(JSON.stringify(processFlow));
        if (isSaved) {
            tempProcessFlow.isSaved = isSaved
        }
        if (!processFlow.id) {
            createFlowAPI.request(token, tempProcessFlow)
        } else {
            updateFlowAPI.request(token, tempProcessFlow)
        }
        setProcessFlow(tempProcessFlow);
    }
    useEffect(() => {
        
    }, [processFlow])

    useEffect(() => {
        

        if (createFlowAPI.data) {
            processChange.current = false
            window.onbeforeunload = null
            createFlowAPI.data.data.isSaved = true
            //setProcessFlow(createFlowAPI.data.data)
            Cookies.set("process-id", createFlowAPI.data.data.id)
            if (chatflowIdCurrent.current == "") {
                navigate(`/canvas/${createFlowAPI.data.data.id}`)
            }
            chatflowIdCurrent.current = createFlowAPI.data.data.id
            setShowSuccess(true)
        }
    }, [createFlowAPI.data, createFlowAPI.error])

    useEffect(() => {
        
        if (updateFlowAPI.data) {
            processChange.current = false
            window.onbeforeunload = null
            updateFlowAPI.data.data.isSaved = true
            //setProcessFlow(updateFlowAPI.data.data)
            setShowSuccess(true)
        }
    }, [updateFlowAPI.data, updateFlowAPI.error])
    const handlePromptFocus = () => {
        setNodes((nds: Node[]) => {
            return nds.map((nd) => {
                return { ...nd, selected: false }
            })
        })
    }
    const [snackbar, setSnackbar] = useState<boolean>(false)
    const dispatch = useDispatch()

    const downloadFile = () => {
        if (processFlow)
        {
            const content = JSON.stringify(processFlow);
            const blob = new Blob([content], { type: 'text' });
            const anchor = document.createElement('a');
            anchor.download = 'result.json';
            anchor.href = window.URL.createObjectURL(blob);
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
       
    };

    return (
        <>
            <header
                className={`bg-white h-16 flex border-b-2  items-center p-0 justify-between shadow-sm absolute top-0 left-0 w-[100vw] z-40 m-0`}
            >
                <Snackbar
                    onClose={() => {
                        setSnackbar(false)
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={snackbar}
                    autoHideDuration={3000}
                    message="Please select a prompt"
                    key={"topcenter"}
                />
                <div className="flex items-center ml-6 gap-6 w-[20%] min-w-[230px]">
                    <div className="h-8 w-8">
                        <ArrowLeftCircleIcon
                            className="text-red-400 cursor-pointer hover:text-red-500"
                            onClick={() => {
                                console.log(processChange.current)
                                if (processChange.current) {
                                    const confirmNavigation = window.confirm(
                                        "You have unsaved changes. Are you sure you want to leave?"
                                    )
                                    if (confirmNavigation) {
                                        navigate("/")
                                    }
                                } else {
                                    navigate("/")
                                }
                            }}
                        />
                    </div>

                    <input
                        type="text"
                        placeholder="Process Flow Name"
                        value={processFlow.name}
                        onFocus={(e) => {
                            handlePromptFocus()
                        }}
                        onChange={(e) => {
                            //handlePromptFocus();
                            setProcessFlow((prev: ProcessFlowType) => {
                                return {
                                    ...prev,
                                    name: e.target.value,
                                }
                            })
                        }}
                        className="outline-gray-200 bg px-2 py-1 font-semibold bg-gray-50 rounded-sm border placeholder-gray-300"
                    />
                </div>
                <div className="hidden lg:block">
                     <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Your Company"
                    /> 
                    
                </div>

                <div className="flex mr-6 w-[20%] min-w-[230px] justify-end">
                    <div
                        title="Run"
                        className="bg-blue-100 p-2 rounded-md cursor-pointer ml-3 hover:bg-blue-200 border border-w-2"
                        onClick={() => handleRun(false)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-blue-800"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                            />
                        </svg>
                    </div>
                    <div
                        title="Save Flow"
                        className="bg-green-100 p-2 rounded-md cursor-pointer ml-3 hover:bg-green-200 border border-w-2"
                        onClick={() => saveFlowHandler(true)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="text-green-800 font-bold h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                            />
                        </svg>
                    </div>


                    <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                            <span className="sr-only">Open user menu</span>

                            <div
                                title="Settings"
                                className="bg-gray-100 p-2 rounded-md cursor-pointer ml-3 hover:bg-gray-200 border border-w-2"
                            >
                                <CogIcon className="text-gray-800 font-bold h-6 w-6" />
                            </div>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active ? "bg-gray-50" : "",
                                                "block px-3 py-1 text-sm leading-6 text-gray-900 w-[100%] text-left"
                                            )}
                                            onClick={downloadFile}
                                        >
                                            Export
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => {}}
                                            className={classNames(
                                                active ? "bg-gray-50" : "",
                                                "block px-3 py-1 text-sm leading-6 text-gray-900 w-[100%] text-left"
                                            )}
                                        >
                                            Import
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <div
                        title="Preview"
                        onClick={() => {
                            console.log(processFlow);
                            if (!checkIfPromptSelected(processFlow)) {
                                setSnackbar(true)
                            } else {
                                handleRun(true)
                            }
                        }}
                        className={`"bg-gray-100 p-2 rounded-md cursor-pointer ml-3 hover:border-w-3 border border-w-2`}
                    >
                        <PreviewImage />
                    </div>
                </div>
                <div className="absolute top-[1000px]">
                    {showSuccess ? (
                        <SuccessAlert
                            show={showSuccess}
                            setShow={setShowSuccess}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </header>

            {isLoading || updateFlowAPI.loading || createFlowAPI.loading ? (
                <ProgressBar />
            ) : (
                <></>
            )}
        </>
    )
}
