import React, { useState, useEffect, SyntheticEvent } from "react"
import CSVTable from "../../ui-components/preview/CSVTable"
import LoadingSpinner from "../../ui-components/common/LoadingSpinner"
import { CSVLink } from "react-csv"
import { ProcessFlowType } from "../../types/flow"
import useApi from "../../hooks/useApi"
import predict from "../../api/predict"
import { TabContext, TabList } from "@mui/lab"
import { Tab, colors } from "@mui/material"
import DownloadDialog from "../../ui-components/modal/DownloadModal"
import { setShowDownloadDialog } from "../../features/flowSlice"
import useAppSelector from "../../hooks/useAppSelector"
import useAppDispatch from "../../hooks/useAppDispatch"
import "./style/style.css"
// or

interface CanvasPreviewProps {
    previewData: any[]
    loading: boolean
    isOpen: boolean
    setIsOpen: any
    previewError: string
    processFlow: ProcessFlowType
}

export default function CanvasPreview({
    previewData,
    loading,
    isOpen,
    setIsOpen,
    previewError,
    processFlow,
}: CanvasPreviewProps) {
    const downloadAPI = useApi(predict.downloadFileAPI)
    const handleTogglePreview = () => {
        setIsOpen(!isOpen)
    }
    const dispatch = useAppDispatch()
    const showDownloadDialog = useAppSelector(
        (state) => state.processReducer.showDownloadDialog
    )
    const [tabValue, setTabValue] = useState("")
    const [tabs, setTabs] = useState<any[]>([])
    const [csvData, setCSVData] = useState("")
    useEffect(() => {
        const tempTab: any = []
        for (const p of previewData) {
            if (processFlow.data?.nodes)
                for (const f of processFlow.data?.nodes) {
                    if (p.node_id == f.id) {
                        tempTab.push({
                            id: f.id,
                            name: f.data.name,
                        })
                    }
                }
        }
        setTabValue("")
        setTabs(tempTab)
    }, [previewData])

    useEffect(() => {
        if (tabValue != "") {
            for (const p of previewData) {
                if (p.node_id == tabValue) {
                    //setCSVData(p.data);
                }
            }
        }
    }, [tabValue])
    useEffect(() => {
        if (tabs.length > 0) setTabValue(tabs[0].id)
    }, [tabs])
    const downloadFile = () => {
        if (downloadAPI.data) {
            const content = downloadAPI.data
            const blob = new Blob([content], { type: "text/csv" })
            const anchor = document.createElement("a")
            anchor.download = "result.csv"
            anchor.href = window.URL.createObjectURL(blob)
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        }
    }

    const callDownloadAPI = () => {
        downloadAPI.request({ userId: processFlow.userId, nodeId: tabValue })
    }
    useEffect(() => {
        downloadFile()
    }, [downloadAPI.data])

    const handleChange = (event: SyntheticEvent, value: string) => {
        setTabValue(value)
        console.log(value)
    }

    const tabSx = { fontSize: "12px", fontWeight: 600 }
    return (
        <div
            className={`bg-gray-100 ${
                isOpen ? "h-[340px]" : "h-[50px]"
            } border-2 shadow-sm absolute bottom-5 overflow-y-auto left-[105px] w-[93%] z-20 m-0`}
        >
            <DownloadDialog
                dialogOpen={showDownloadDialog}
                data={{
                    nodeId: tabValue,
                    userId: processFlow.userId,
                }}
            />
            <header className="w-[100%] h-[40px] bg-white flex justify-between items-center border-b-2 px-2">
                <h1 className="font-semibold text-gray-500">Preview Results</h1>
                <div className="flex items-center justify-between w-[150px]">
                    <button
                        onClick={() => {
                            dispatch(setShowDownloadDialog(true))
                        }}
                        className="rounded bg-red-400 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                        //filename="result.csv"
                        //data={previewData}
                    >
                        DOWNLOAD
                    </button>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className={`w-8 h-8 text-red-400 hover:text-red-500 ${
                            isOpen ? "rotate-180" : ""
                        } cursor-pointer`}
                        onClick={handleTogglePreview}
                    >
                        <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
            </header>
            {tabs.length > 1 && (
                <>
                        
                <header className="w-[100%] h-[40px] bg-white flex items-center border-b-2 px-2 overflow-y-hidden">
                        
                        <TabContext value={tabValue}>
                            <TabList
                                variant="scrollable"
                                scrollButtons="auto"
                                onChange={handleChange}
                                aria-label="forced scroll tabs example"
                                sx={{ borderBottom: `0px solid red` }}
                            >
                                {tabs.map((t) => (
                                    <Tab
                                        sx={tabSx}
                                        value={t.id}
                                        label={t.name}
                                    />
                                ))}
                            </TabList>
                        </TabContext>
                    </header>
                </>
            )}
            {loading ? (
                <div className="h-[100%] w-[100%] flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <></>
            )}
            {previewError && (
                <div
                    className={`h-[85%] w-[100%] overflow-auto bg-gray-100 px-4`}
                >
                    Something went wrong{" "}
                </div>
            )}
            {previewData.map((p) => (
                <div
                    key={p.node_id}
                    style={{
                        width: `${tabValue == p.node_id ? "100%" : "0"}`,
                        height: `${tabValue == p.node_id ? "290px" : "0"}`,
                    }}
                >
                    
                    <CSVTable
                        nodeId={p.node_id}
                        processFlow={processFlow}
                        previewError={previewError}
                        loading={loading}
                        isOpen={isOpen}
                        csvData={p.data}
                    />
                    
                </div>
            ))}

            {/* )}

                {previewError ? previewError : ""}
            </div> */}
        </div>
    )
}
