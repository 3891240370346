import MinimalLayout from "../ui-components/layout/MinimalLayout"
import Canvas from "../views/canvas"
import PrivateRoute from "./PrivateRoute"
const FlowRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "/canvas/",
            element: <PrivateRoute element={<Canvas />} />,
        },
        {
            path: "/canvas/:id",
            element: <PrivateRoute element={<Canvas />} />,
        },
    ],
}

export default FlowRoutes
