import { useEffect, useState } from "react"

import logo from "../../assets/images/newlogo.png"
import { Link } from "react-router-dom"
import { Dialog } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import LogoImage from "../../assets/imageJS/logo"

const navigation = [
    // { name: "Product", href: "#" },
    // { name: "Features", href: "#" },
    // { name: "Marketplace", href: "#" },
    { name: "Home", href: "#" },
]
interface AuthHeaderProps {
    type: "login" | "register"
}
const AuthHeader = ({ type }: AuthHeaderProps) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="bg-white">
            <nav
                className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
                aria-label="Global"
            >
                <a href="#" className="-m-1.5 p-1.5">
                    
                    {/* <div className="flex h-auto w-40 items-center">
                                        <LogoImage />
                                    </div> */}
                                    <img className="h-8 w-auto" src={logo} alt="" />
                </a>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            {item.name}
                        </a>
                    ))}

                    {type === "login" ? (
                        <Link
                            to={"/login"}
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            LOG IN <span aria-hidden="true">&rarr;</span>
                        </Link>
                    ) : (
                        <Link
                            to={"/register"}
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            SIGN UP <span aria-hidden="true">&rarr;</span>
                        </Link>
                    )}
                </div>
            </nav>
            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            {/* <div className="flex h-8 shrink-0 items-center">
                                        <LogoImage />
                                    </div> */}
                            <img className="h-12 w-auto" src={logo} alt="" />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>

                            {type === "login" ? (
                                <div className="py-6">
                                    <Link
                                        to={"/login"}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        LOG IN
                                    </Link>
                                </div>
                            ) : (
                                <div className="py-6">
                                    <Link
                                        to={"/register"}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        SIGN UP
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default AuthHeader
