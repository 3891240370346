import type { PropsWithChildren, CSSProperties, FC } from "react"
import { useDrag } from "react-dnd"

import { ItemTypes } from "../../utils/ItemTypes"

const style: CSSProperties = {
    border: "1px dashed gray",
    backgroundColor: "white",
    padding: "0.5rem 1rem",
    marginRight: "1.5rem",
    marginBottom: "1.5rem",
    cursor: "move",
    float: "left",
}

export interface BoxProps {
    name: string
}

interface DropResult {
    monitor: any
}

interface SidebarItemProps extends PropsWithChildren {
    name: string
    handler: any,

}

export const SidebarItem = function Box({
    name,
    children,

    handler
}: SidebarItemProps) {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.BOX,
        item: { name },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            const x = dropResult?.monitor.x;
            const y = dropResult?.monitor.y;
            const gg =  monitor.getClientOffset(); //monitor.getDifferenceFromInitialOffset()

            if (item && dropResult) {
                console.log(item, gg)
                handler({x,y, zoomLevel: dropResult?.monitor.zoomLevel, viewPortX: dropResult?.monitor.viewPortX, viewPortY: dropResult?.monitor.viewPortY})
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))

    const opacity = isDragging ? 0.4 : 1
    return (
        <div ref={drag} data-testid={`box`}>
            {children}
        </div>
    )
}
