import { AxiosResponse } from "axios"
import { ProcessFlowType } from "../types/flow"
import { apiClient } from "./client"
import APIResponse from "../types/response"
import { IUser } from "../types/user"
import { UserData } from "../types/auth"

// const getAllAPIKeys = () => client.get('/apikey')

const getAuthenticatedUserInfoApi = (
    token: string
): Promise<AxiosResponse<APIResponse<IUser>>> =>
    apiClient.get(`/v1/users/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    const updateUserAccessToken = (
        token: string,
        accessToken: string
    ): Promise<AxiosResponse<APIResponse<any>>> =>
        apiClient.post(`/v1/users/updateUserGoogleToken`,{access_token: accessToken}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

const updateUser = (
    token: string,
    body: any
): Promise<AxiosResponse<APIResponse<UserData>>> =>
    apiClient.post(`/v1/users/updateUser`, body, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

const changePassword = (
    token: string,
    body: any
): Promise<AxiosResponse<APIResponse<any>>> =>
    apiClient.post(`/v1/auth/changePassword`, body, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
const confirmEmailApi = (
    emailToken: string
): Promise<AxiosResponse<APIResponse<null>>> =>
    apiClient.get(`/v1/auth/confirm/${emailToken}`)

const verifyTokenApi = (
    token: string
): Promise<AxiosResponse<APIResponse<null>>> =>
    apiClient.post(`/v1/auth/verifyToken`, {
        resetToken: token,
    })

const getUserInfo = async (token: string) => {
    const response = await apiClient.get(`/v1/users/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

const updateBalanceAPI = async (token: string, balance: any) => {
    const response = await apiClient.post(
        `/v1/users/updateUserBalance`,
        {
            balance,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    )

    return response.data
}

const checkUserAccessToken = async (token: string, access_token: string) => {
    const response = await apiClient.get(
        `/v1/google-sheet/token?access_token=${access_token}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    )
    return response.data
}
// const updateAPI = (id, body) => client.put(`/apikey/${id}`, body)

// const deleteAPI = (id) => client.delete(`/apikey/${id}`)

export default {
    getAuthenticatedUserInfoApi,
    confirmEmailApi,
    updateUser,
    changePassword,
    getUserInfo,
    updateBalanceAPI,
    updateUserAccessToken,
    checkUserAccessToken
}
