import { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import logo from "../../assets/images/newlogo.png"
import {
    ChartPieIcon,
    Cog6ToothIcon,
    FolderIcon,
    UsersIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline"
import "./SideBarComponent.css"
import "./../../style/misc.css"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
//import logo from "../../assets/images/logo.png"
//import logo from "../../assets/images/twangle_logo.svg"
import { Link, useLocation } from "react-router-dom"
import IPCChannels from "./../../config/constants"
import useAppSelector from "../../hooks/useAppSelector"
import { UserTypeName, UserTypeQuota } from "../../types/auth"
import IPlans from "../../types/plan"
import LogoImage from "../../assets/imageJS/logo"
import { IPCEventType } from "../../types/ipc"
import { IpcRenderer } from "electron"
import axios from "axios"
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

interface Props {
    sidebarOpen: boolean
    setSidebarOpen: any
}
export default function SidebarComponent({
    sidebarOpen,
    setSidebarOpen,
}: Props) {
    const location = useLocation()
    const userData = useAppSelector((state) => state.auth.userData)

    const allProcess = useAppSelector(
        (state) => state.processReducer.allProcess
    )
    const token = useAppSelector((state) => state.auth.token)
    const [savedProcessCount, setSavedProcessCount] = useState<number>(0)
    const [chatButtonDisabled, setChatButtonDisabled] = useState(false)
    useEffect(() => {
        let count = 0
        if (allProcess && allProcess.length) {
            allProcess.map((p) => {
                if (p.isSaved === true) count++
            })

            setSavedProcessCount(count)
        }
    }, [allProcess])
    const plans = useAppSelector((state) => state.auth.plans)
    const [userPlan, setUserPlan] = useState<IPlans>()
    useEffect(() => {
        if (plans && plans.length > 0) {
            for (const p of plans) {
                console.log(p)
                
                if (userData.userTypeName === p.userTypeName) {
                    setUserPlan(p)
                }
            }
        }
    }, [plans])

    function openChatApp() {
        let ipcRenderer: IpcRenderer | null = null
        try {
            const electron = window.require("electron")
            const data: IPCEventType = {
                event: IPCChannels.events.OPEN_CHAT_APP,
                data: {
                    token,
                },
            }
            ipcRenderer = electron.ipcRenderer 
            ipcRenderer && ipcRenderer.send(IPCChannels.channels.CHAT_APP, data)
            
            setChatButtonDisabled(true)

            setInterval(async () => {
                try {
                    if (ipcRenderer){
                        const response = await axios.get(process.env.REACT_APP_CHAT_APP_URL as string);
                        if (response.status !== 200) throw 'link error'
                        
                        ipcRenderer.send(IPCChannels.channels.CHAT_APP, data)
                    }
                        
                } catch (e) {
                    console.log(e);
                }
            }, 8000)
        } catch (e) {


            try {
                if (process.env.REACT_APP_CHAT_APP_URL)
                    window.open(process.env.REACT_APP_CHAT_APP_URL+`?token=${token}`, "_blank")
            } catch (e) {}
        }
    }
    const navigation = [
        {
            name: "Dashboard",
            href: "/dashboard",
            icon: FolderIcon,
            current: location.pathname === "/dashboard",
        },
        {
            name: "Process Flows",
            href: "/processflow",
            icon: ChartPieIcon,
            current: location.pathname === "/processflow",
        },
        {
            name: "Account Settings",
            href: "/users",
            icon: UsersIcon,
            current: location.pathname === "/users",
        },
        {
            name: "Get Help",
            href: "/help",
            icon: QuestionMarkCircleIcon,
            current: location.pathname === "/help",
        },
    ]
    const teams = [
        { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
        {
            id: 2,
            name: "Tailwind Labs",
            href: "#",
            initial: "T",
            current: false,
        },
        { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
    ]

    let percentage = 0
    if (userData?.balance && userData?.userTypeName)
        percentage =
            userData.balance /
            (userPlan?.tokens || 1)
    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={() => {
                        setSidebarOpen(false)
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() =>
                                                setSidebarOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                    {/* <div className="flex h-auto w-30 items-center">
                                        <LogoImage />
                                    </div> */}
                                    <img className="h-8 w-auto" src={logo} alt="" />
                                    <nav className="flex flex-1 flex-col">
                                        <ul
                                            role="list"
                                            className="flex flex-1 flex-col gap-y-7"
                                        >
                                            <li>
                                                <ul
                                                    role="list"
                                                    className="-mx-2 space-y-1"
                                                >
                                                    {navigation.map((item) => (
                                                        <li key={item.name}>
                                                            <Link
                                                                to={item.href}
                                                                className={classNames(
                                                                    item.current
                                                                        ? "bg-gray-50 text-red-500"
                                                                        : "text-gray-700 hover:text-red-500 hover:bg-gray-50",
                                                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                                )}
                                                            >
                                                                {item.name.includes(
                                                                    "Process"
                                                                ) ? (
                                                                    <svg
                                                                        width="20px"
                                                                        height="20px"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5ZM9 7.82929C10.1652 7.41746 11 6.30622 11 5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5C5 6.30622 5.83481 7.41746 7 7.82929V9V16C7 18.2091 8.79086 20 11 20H14.1707C14.5825 21.1652 15.6938 22 17 22C18.6569 22 20 20.6569 20 19C20 17.3431 18.6569 16 17 16C15.6938 16 14.5825 16.8348 14.1707 18H11C9.89543 18 9 17.1046 9 16V12.4649C9.58835 12.8052 10.2714 13 11 13H14.1707C14.5825 14.1652 15.6938 15 17 15C18.6569 15 20 13.6569 20 12C20 10.3431 18.6569 9 17 9C15.6938 9 14.5825 9.83481 14.1707 11H11C9.89543 11 9 10.1046 9 9V7.82929ZM16 12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12ZM16 19C16 19.5523 16.4477 20 17 20C17.5523 20 18 19.5523 18 19C18 18.4477 17.5523 18 17 18C16.4477 18 16 18.4477 16 19Z"
                                                                            fill={
                                                                                item.current
                                                                                    ? "#ef4414"
                                                                                    : "#000000"
                                                                            }
                                                                        />
                                                                    </svg>
                                                                ) : (
                                                                    <item.icon
                                                                        className={classNames(
                                                                            item.current
                                                                                ? "text-red-500"
                                                                                : "text-gray-400 group-hover:text-red-500",
                                                                            "h-6 w-6 shrink-0"
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            {/* <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          Your teams
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {teams.map((team) => (
                            <li key={team.name}>
                              <Link
                                to={team.href}
                                className={classNames(
                                  team.current
                                    ? "bg-gray-50 text-red-500"
                                    : "text-gray-700 hover:text-red-500 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <span
                                  className={classNames(
                                    team.current
                                      ? "text-red-500 border-red-500"
                                      : "text-gray-400 border-gray-200 group-hover:border-red-500 group-hover:text-red-500",
                                    "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                  )}
                                >
                                  {team.initial}
                                </span>
                                <span className="truncate">{team.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li> */}

                                            {/* <li className="mt-auto">
                        <Link
                          to="#"
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-500"
                        >
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-500"
                            aria-hidden="true"
                          />
                          Settings
                        </Link>
                      </li> */}
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                    <div
                        style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        {/* <div style={{ width: "70%", textAlign: "center" }}>
                            <LogoImage />
                        </div> */}
                        <img className="h-8 w-auto" src={logo} alt="" />
                    </div>
                    {chatButtonDisabled ? (
                        <div className="loader"></div>
                    ) : (
                        <button
                            onClick={openChatApp}
                            disabled={chatButtonDisabled}
                            className={`flex w-full font-sans justify-center bg-red-500 rounded-md border border-red-500 py-2 px-4 text-sm font-bold text-white shadow-sm hover:border-red-300 focus:outline-none focus:ring-2 focus:ring-red-100 focus:ring-offset-2 `}
                        >
                            Chat with Data
                        </button>
                    )}
                    <nav className="flex flex-1 flex-col">
                        <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                        >
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {navigation.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? "bg-gray-50 text-red-500"
                                                        : "text-gray-700 hover:text-red-500 hover:bg-gray-50",
                                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                )}
                                            >
                                                {item.name.includes(
                                                    "Process"
                                                ) ? (
                                                    <svg
                                                        width="20px"
                                                        height="20px"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5ZM9 7.82929C10.1652 7.41746 11 6.30622 11 5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5C5 6.30622 5.83481 7.41746 7 7.82929V9V16C7 18.2091 8.79086 20 11 20H14.1707C14.5825 21.1652 15.6938 22 17 22C18.6569 22 20 20.6569 20 19C20 17.3431 18.6569 16 17 16C15.6938 16 14.5825 16.8348 14.1707 18H11C9.89543 18 9 17.1046 9 16V12.4649C9.58835 12.8052 10.2714 13 11 13H14.1707C14.5825 14.1652 15.6938 15 17 15C18.6569 15 20 13.6569 20 12C20 10.3431 18.6569 9 17 9C15.6938 9 14.5825 9.83481 14.1707 11H11C9.89543 11 9 10.1046 9 9V7.82929ZM16 12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12ZM16 19C16 19.5523 16.4477 20 17 20C17.5523 20 18 19.5523 18 19C18 18.4477 17.5523 18 17 18C16.4477 18 16 18.4477 16 19Z"
                                                            fill={
                                                                item.current
                                                                    ? "#ef4414"
                                                                    : "#000000"
                                                            }
                                                        />
                                                    </svg>
                                                ) : (
                                                    <item.icon
                                                        className={classNames(
                                                            item.current
                                                                ? "text-red-500"
                                                                : "text-gray-400 group-hover:text-red-500",
                                                            "h-6 w-6 shrink-0"
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                )}
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {/* <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  Your teams
                </div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {teams.map((team) => (
                    <li key={team.name}>
                      <Link
                        to={team.href}
                        className={classNames(
                          team.current
                            ? "bg-gray-50 text-red-500"
                            : "text-gray-700 hover:text-red-500 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <span
                          className={classNames(
                            team.current
                              ? "text-red-500 border-red-500"
                              : "text-gray-400 border-gray-200 group-hover:border-red-500 group-hover:text-red-500",
                            "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                          )}
                        >
                          {team.initial}
                        </span>
                        <span className="truncate">{team.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li> */}
                            <li className="mt-auto">
                                <div className="w-100 font-sans p-0 m-0">
                                    <p className="font-semibold p-0 m-0">
                                        {
                                            UserTypeName[
                                                userData?.userTypeName as keyof typeof UserTypeName
                                            ]
                                        }{" "}
                                        Plan
                                    </p>
                                    <div className="w-100 flex justify-between text-sm p-0 m-0">
                                        <p>Actions</p>
                                        <p>
                                            {userData?.balance &&
                                                userData.balance}
                                            /
                                            {userData?.userTypeName &&
                                                userPlan?.tokens}
                                        </p>
                                    </div>
                                    <progress
                                        className="progress"
                                        value={percentage}
                                    />
                                    <div className="w-100 flex justify-between text-sm">
                                        <p>Process Flows</p>
                                        <p>
                                            {savedProcessCount}/
                                            {userPlan?.flows}
                                        </p>
                                    </div>
                                    <progress
                                        className="progress"
                                        value={
                                            savedProcessCount /
                                            (userPlan?.flows || 1)
                                        }
                                    />
                                    <p className="text-sm">
                                        Monthly Reset Usage in 4 weeks
                                    </p>
                                    <Link
                                        className="text-sm text-sky-700 underline"
                                        to={`/`}
                                    >
                                        Manage Plan
                                    </Link>
                                    <button className="my-2 flex w-full justify-center rounded-md border border-cyan-600 py-2 px-4 text-sm font-medium text-cyan-600 shadow-sm hover:cyan-100 focus:outline-none focus:ring-2 focus:ring-cyan-100 focus:ring-offset-2 ">
                                        Upgrade Plan
                                    </button>
                                </div>
                            </li>
                            {/* <li className="mt-auto">
                <Link
                  to="#"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-500"
                >
                  <Cog6ToothIcon
                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-500"
                    aria-hidden="true"
                  />
                  Settings
                </Link>
              </li> */}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}
