import { useCallback, useState, useEffect } from "react"
import { Node, NodeProps, Handle, Position, useReactFlow } from "reactflow"
import NodeCard from "../../../ui-components/canvas/node/NodeCard"
import Arrow from "../../../ui-components/canvas/node/Arrow"

type NodeData = {
    content: string
    order: number | null
    arrowPositions: string[]
    isEditing: boolean
}

type PromptNode = Node<NodeData>

export default function PromptNode({ data, id }: NodeProps<NodeData>) {
    const { getNode, setNodes } = useReactFlow()

    const [positions, setPositions] = useState<Position[]>([])
    const node = getNode(id)
    const onChange = (evt: any) => {
        // Update the node's data when input changes
        setNodes((prev) => {
            return prev.map((nd) => {
                if (nd.id === id) {
                    nd.data = {
                        ...nd.data,
                        text: evt.target.value,
                    }
                }
                return nd
            })
        })
        return
    }

    useEffect(() => {
        const pts: Position[] = []
        data.arrowPositions?.forEach((p) => {
            if (p === "Top") {
                pts.push(Position.Top)
            } else if (p === "Left") {
                pts.push(Position.Left)
            } else if (p === "Bottom") {
                pts.push(Position.Bottom)
            } else {
                pts.push(Position.Right)
            }
        })
        setPositions(pts)
    }, [data.arrowPositions])
    return (
        <NodeCard
            title={node?.data.name || "Prompt"}
            description={node?.data?.content || ""}
            icon={
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="text-gray-800"
                >
                    <path
                        fillRule="evenodd"
                        d="M2.25 6a3 3 0 013-3h13.5a3 3 0 013 3v12a3 3 0 01-3 3H5.25a3 3 0 01-3-3V6zm3.97.97a.75.75 0 011.06 0l2.25 2.25a.75.75 0 010 1.06l-2.25 2.25a.75.75 0 01-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06zm4.28 4.28a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                        clipRule="evenodd"
                    />
                </svg>
            }
            selected={node?.selected || false}
        >
            <Handle
                type="target"
                id="Top"
                position={Position.Top}
                isConnectable={true}
            ></Handle>

            <Handle
                type="source"
                id="Bottom"
                position={Position.Bottom}
                isConnectable={true}
            />
            <Handle
                type="target"
                id="Left"
                position={Position.Left}
                isConnectable={true}
            />
            <Handle
                type="source"
                id="Right"
                position={Position.Right}
                isConnectable={true}
            />
            {positions.map((position: Position, i: number) => (
                <Arrow key={i} position={position} />
            ))}
        </NodeCard>
    )
}
