import { createSlice } from "@reduxjs/toolkit";
import { ProcessFlowType } from "../types/flow";

interface PromptDescState {
      process: ProcessFlowType | null
      show: boolean
  }
  
  const initialState: PromptDescState = {
      process : null,
      show: false,
  }

export const promptDescSlice = createSlice({
      name: "promptDesc",
      initialState,
      reducers: {
            showPrompt: (state, action) => {
                  state.process = action.payload.process
                  state.show = true
            },
            resetPrompt: (state) => {
                  state.process = null
                  state.show = false
            },
            
      }
});


// Action creators are generated for each case reducer function
export const { showPrompt, resetPrompt } = promptDescSlice.actions;

export default promptDescSlice.reducer;
