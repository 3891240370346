import { createSlice } from "@reduxjs/toolkit";
import { CSVData, ProcessFlowType } from "../types/flow";

interface NodesState {
    csvData: CSVData,
    sheets: string[]
  }
  
  const initialState: NodesState = {
      csvData: {
            fileName: '',
            fileType: '',
            fileData: '',
            sheetName: '',
            filePath: '',
      },
      sheets: []
  }

export const nodesSlice = createSlice({
      name: "nodes",
      initialState,
      reducers: {
            setCSVData: (state, action) => {
                  state.csvData = action.payload
            },         
            setSheets:  (state, action) => {
                  state.sheets = action.payload
            },  
      }
});


// Action creators are generated for each case reducer function
export const { setCSVData, setSheets  } = nodesSlice.actions;

export default nodesSlice.reducer;
