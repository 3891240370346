import { Navigate } from "react-router-dom"
import MainLayout from "../ui-components/layout/MainLayout"
import Dashboard from "../views/dashboard"
import ProcessFlow from "../views/processflow"
import Help from "../views/help"
import PrivateRoute from "./PrivateRoute" // Import the PrivateRoute component
import User from "../views/user"
const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            // Set an index route for '/'
            index: true,
            element: <Navigate to="dashboard" />, // Redirect to '/dashboard'
        },
        {
            path: "dashboard",
            element: <PrivateRoute element={<Dashboard />} />,
        },
        {
            path: "processflow",
            element: <PrivateRoute element={<ProcessFlow />} />,
        },
        {
            path: "users",
            element: <PrivateRoute element={<User />} />,
        },
        {
            path: "help",
            element: <PrivateRoute element={<Help />} />,
        },
    ],
}

export default MainRoutes
