import { ProcessFlowType } from "../../types/flow";

export function getRecentItems(processFlows: ProcessFlowType[], itemLength: number)
{
    let recentItems = [];
    const tempProcessFlowsNull = processFlows.filter(f=> f.updatedAt == null || f.updatedAt == undefined);
    const tempProcessFlowsNotNulls = processFlows.filter(f=> f?.updatedAt);
    for (const a of tempProcessFlowsNotNulls)
        {
            recentItems.push(a);
            //if (recentItems.length == itemLength && itemLength != 0) break;
        }
        
        recentItems = recentItems.sort((a, b) => {
            const aD = new Date(a.updatedAt);
            const bD = new Date(b.updatedAt);
            return bD.getTime() - aD.getTime() ;//return a.trade_time_stamp - b.trade_time_stamp;
        });
        recentItems = recentItems.filter(a=> a.isSaved == true);
        if (itemLength != 0) {
            recentItems.splice(8);
        }
        for (const a of tempProcessFlowsNull)
        {
    
            if (recentItems.length == itemLength && itemLength != 0) break;
            recentItems.push(a);
        }

        return recentItems
}