// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
      border: 8px solid #f3f3f3;
      border-radius: 50%;
      border-top: 8px solid #F76E72;
      width: 50px;
      height: 50px; /* Safari */
      animation: spin 2s linear infinite;
      margin-left: 50%;
      margin-top: 25%;
    }
    
    /* Safari */
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .flow-card {
      aspect-ratio: 1 / 1;
      border-radius: 10px;
      padding: 0px;
      position: relative;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.038);
      border: 1px solid rgba(0, 0, 0, 0.038);
      transition: 0.3s;
    }
    .flow-card:hover{
      
      border: 1px solid rgba(239,68,68, 0.374);
      box-shadow: 4px 4px 4px rgba(239,68,68, 0.374);
    }`, "",{"version":3,"sources":["webpack://./src/style/misc.css"],"names":[],"mappings":"AAAA;MACM,yBAAyB;MACzB,kBAAkB;MAClB,6BAA6B;MAC7B,WAAW;MACX,YAAY,EACgC,WAAW;MACvD,kCAAkC;MAClC,gBAAgB;MAChB,eAAe;IACjB;;IAEA,WAAW;;IAMX;MACE,KAAK,uBAAuB,EAAE;MAC9B,OAAO,yBAAyB,EAAE;IACpC;;IAEA;MACE,mBAAmB;MACnB,mBAAmB;MACnB,YAAY;MACZ,kBAAkB;MAClB,4CAA4C;MAC5C,sCAAsC;MACtC,gBAAgB;IAClB;IACA;;MAEE,wCAAwC;MACxC,8CAA8C;IAChD","sourcesContent":[".loader {\n      border: 8px solid #f3f3f3;\n      border-radius: 50%;\n      border-top: 8px solid #F76E72;\n      width: 50px;\n      height: 50px;\n      -webkit-animation: spin 2s linear infinite; /* Safari */\n      animation: spin 2s linear infinite;\n      margin-left: 50%;\n      margin-top: 25%;\n    }\n    \n    /* Safari */\n    @-webkit-keyframes spin {\n      0% { -webkit-transform: rotate(0deg); }\n      100% { -webkit-transform: rotate(360deg); }\n    }\n    \n    @keyframes spin {\n      0% { transform: rotate(0deg); }\n      100% { transform: rotate(360deg); }\n    }\n\n    .flow-card {\n      aspect-ratio: 1 / 1;\n      border-radius: 10px;\n      padding: 0px;\n      position: relative;\n      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.038);\n      border: 1px solid rgba(0, 0, 0, 0.038);\n      transition: 0.3s;\n    }\n    .flow-card:hover{\n      \n      border: 1px solid rgba(239,68,68, 0.374);\n      box-shadow: 4px 4px 4px rgba(239,68,68, 0.374);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
