import { CogIcon } from "@heroicons/react/24/outline"
import { PropsWithChildren, useEffect } from "react"
import Arrow from "./Arrow"

interface NodeCardProps extends PropsWithChildren {
    icon: any
    title: string
    description: string
    selected: boolean
}

export default function ({
    children,
    icon,
    title,
    description,
    selected,
}: NodeCardProps) {
    const formattedDescription =
        description.length > 15 ? description.slice(0, 15) + "..." : description

    return (
        <div
            className={`shadow-sm flex items-center justify-left gap-2 bg-white p-2 rounded-lg h-20 w-[200px] border relative ${
                selected ? "border-red-100 border-2 " : "border-gray-200"
            }`}
        >
            <div className="w-16 h-16 flex items-center justify-center">
                {icon}
            </div>
            <div>
                <h1 className="font-bold text-sm">{title}</h1>
                <p className="text-xs">{formattedDescription}</p>
            </div>
            {children}
        </div>
    )
}
