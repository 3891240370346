export interface UserData {
    firstName: string
    lastName: string
    email: string
    password: string
    companyName?: string
    balance?: number
    userTypeName?: string
    gsheet_access_token?: string
}

export interface UserCredentials {
    email: string
    password: string
}

export enum UserTypeName {
    Free = "Free",
    Pro = "Professional",
    Business = "Business",
    Admin = "Admin",
}

export enum UserTypeQuota {
    Free = 10,
    Pro = 1000,
    Business = 10000,
}
