import { useEffect } from "react"
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom"
import useAppSelector from "../hooks/useAppSelector"
import useApi from "../hooks/useApi"
import user from "../api/user"
import ProgressBar from "../ui-components/common/ProgressBar"
import { googleAuthenticateUser, setPlatform, setUserData } from "../features/auth/authSlice"
import useAppDispatch from "../hooks/useAppDispatch"

interface PrivateRouteProps {
    element: any
}
const PrivateRoute = ({ element }: PrivateRouteProps) => {
    const dispatch = useAppDispatch()

    const token = useAppSelector((state) => state.auth.token)

    const getAuthenticatedUserInfoApi = useApi(user.getAuthenticatedUserInfoApi)

    useEffect(() => {
        dispatch(setPlatform(null))
        getAuthenticatedUserInfoApi.request(token)
    }, [])

    useEffect(() => {
        if (getAuthenticatedUserInfoApi.data) {
            dispatch(
                setUserData({
                    firstName:
                        getAuthenticatedUserInfoApi?.data?.data.firstName,
                    lastName: getAuthenticatedUserInfoApi?.data?.data?.lastName,
                    email: getAuthenticatedUserInfoApi?.data?.data?.email,
                    password: "",
                    userTypeName:
                        getAuthenticatedUserInfoApi?.data?.data?.userTypeName,
                    companyName:
                        getAuthenticatedUserInfoApi?.data?.data?.companyName,
                    balance: getAuthenticatedUserInfoApi?.data?.data?.balance,
                    gsheet_access_token: getAuthenticatedUserInfoApi.data.data.gsheet_access_token
                })
            )
        }
    }, [getAuthenticatedUserInfoApi.data])
    return (
        <>
            {!getAuthenticatedUserInfoApi.data && (
                <div className="w-[100vw] h-[100vh] bg-white absolute top-0 left-0 z-50">
                    <ProgressBar />
                </div>
            )}
            {getAuthenticatedUserInfoApi.data && element}

            {getAuthenticatedUserInfoApi.error && <Navigate to={"/login"} />}
        </>
    )
}

export default PrivateRoute
