import { useEffect, useRef } from "react";
import flow from "../../api/flow";
import { resetPrompt } from "../../features/promptDescSlice";
import useApi from "../../hooks/useApi";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { ProcessFlowType } from "../../types/flow";

function DescriptionInputModel(){
    const promptData = useAppSelector(state => state.promptDescReducer);
    const token = useAppSelector((state) => state.auth.token)
    const updateFlowApi = useApi(flow.updateFlow)
    const promptInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch()
    const hidePrompt = () => {
        dispatch(resetPrompt())
    }
    useEffect(()=>{
        if(updateFlowApi.data)
        dispatch(resetPrompt())
    },[updateFlowApi.data])
    const savePrompt = () => {
        const tempData  = {...promptData.process, description:  promptInputRef?.current?.value || ''};

        if (tempData)
        {
            updateFlowApi.request(token, tempData)
        }
    }

    return (<div id="description-input-modal" aria-hidden="true" className="bg-black/20 overflow-y-auto overflow-x-hidden fixed top-0 right-0 z-50 justify-center items-center w-full h-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 w-full max-w-md max-h-full">

        <div className="bg-white rounded-lg shadow  top-100 left-50 transform -translate-x-50 -translate-y-50">

            

        <div className="p-4 md:p-5">
                <form className="space-y-4" action="#">
                    <div>
                        <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Change Process Description</label>
                        <input maxLength={210} defaultValue={promptData?.process?.description || ""} ref={promptInputRef} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
                    </div>
                    <div className="flex flex-colm space-between">
                    <button onClick={savePrompt} className="w-full mr-4 text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                    <button onClick={hidePrompt} className="w-full text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div> )
}
export default DescriptionInputModel;


