import React, { useState, useEffect } from "react"
import { DocumentPlusIcon } from "@heroicons/react/24/outline"
import { Node } from "reactflow"
import Checkmark from "./Checkmark"
import * as XLSX from "xlsx"
import Papa from "papaparse"
import useAppSelector from "../../../hooks/useAppSelector"
import { platform, setUserData } from "../../../features/auth/authSlice"
import { useGoogleLogin } from "@react-oauth/google"
import GoogleImage from "./../../../assets/images/google.png"
import IPCChannels from "./../../../config/constants"
import { getIPCRenderer } from "../../../services/ipc"
import { CSVData } from "../../../types/flow"
import { setCSVData, setSheets } from "../../../features/nodesSlice"
import useAppDispatch from "../../../hooks/useAppDispatch"
import user from "../../../api/user"
import useApi from "../../../hooks/useApi"
//const { ipcRenderer } = require('electron');
interface DocumentInputProps {
    selectedNode: Node<any>
    setSelectedNode: any
    setDisabled: any
    handleDocumentUpload: (data: CSVData) => void
}

const formatCSV = (csvString: string) => {
    // Parse CSV string using PapaParse
    const parsedData = Papa.parse(csvString, {
        header: true,
        skipEmptyLines: true,
    })

    // Extract headers and data
    const headers = parsedData.meta?.fields
    const data = parsedData?.data
    if (!headers || !data) {
        return ""
    }

    // Format data as a CSV string
    const formattedCSV = [headers.join(",")]
    data.forEach((row: any) => {
        const rowValues = headers.map((header) => row[header])
        formattedCSV.push(rowValues.join(","))
    })

    return formattedCSV.join("\n")
}

export default function DocumentInput({
    selectedNode,
    setSelectedNode,
    handleDocumentUpload,
    setDisabled,
}: DocumentInputProps) {
    const _platform = useAppSelector((state) => state.auth.platform)
    const userData = useAppSelector((state) => state.auth.userData)
    const token = useAppSelector((state) => state.auth.token)
    const { csvData, sheets } = useAppSelector((state) => state.nodesReducer)
    const [isFile, setIsFile] = useState<boolean>(csvData.fileType !== 'GOOGLE_SHEET') 
    const dispatch = useAppDispatch()
    const [showCheckmark, setShowCheckmark] = useState(false)

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) =>{
            if (token)
            {
                const response = await user.updateUserAccessToken(token, codeResponse.access_token)
                if (response)
                {
                    getAuthenticatedUserInfoApi.request(token)
                }
            }
        },
        onError: (error) => console.log("Login Failed:", error) ,
         scope:"https://www.googleapis.com/auth/spreadsheets.readonly"
    })

    const getAuthenticatedUserInfoApi = useApi(user.getAuthenticatedUserInfoApi)

    useEffect(() => {
        if (getAuthenticatedUserInfoApi.data) {
            console.log(getAuthenticatedUserInfoApi.data)
            dispatch(
                setUserData({
                    firstName:
                        getAuthenticatedUserInfoApi?.data?.data.firstName,
                    lastName: getAuthenticatedUserInfoApi?.data?.data?.lastName,
                    email: getAuthenticatedUserInfoApi?.data?.data?.email,
                    password: "",
                    userTypeName:
                        getAuthenticatedUserInfoApi?.data?.data?.userTypeName,
                    companyName:
                        getAuthenticatedUserInfoApi?.data?.data?.companyName,
                    balance: getAuthenticatedUserInfoApi?.data?.data?.balance,
                    gsheet_access_token: getAuthenticatedUserInfoApi.data.data.gsheet_access_token
                })
            )
        }
    }, [getAuthenticatedUserInfoApi.data])


    const [isExcel, setIsExcel] = useState(
        csvData.sheetName !== "" &&
            csvData.sheetName !== null &&
            csvData.sheetName !== undefined
            ? true
            : false
    )

    function selectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedValue = event.target.value
        if (selectedValue === "FILE") setIsFile(true)
        else setIsFile(false)
    }

    useEffect(() => {
        // if (isExcel) {
        //     const workbook = XLSX.read(csvData.fileData, { type: "base64" })
        //     // Extract sheet names
        //     const sheetNames = workbook.SheetNames || []
        //     dispatch(setSheets(sheetNames))
        // }
        return () => {
            setDisabled(false)
        }
    }, [])

    useEffect(() => {
        if (selectedNode) {
            dispatch(
                setCSVData({
                    fileName: selectedNode.data.fileName,
                    fileType: selectedNode.data.fileType,
                    fileData: selectedNode.data.fileData,
                    sheetName: selectedNode.data.sheetName,
                    filePath: selectedNode.data.filePath,
                    
                })
            )
        }
    }, [selectedNode])

    const onSheetSelectionChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedValue = event.target.value
        dispatch(setCSVData({ ...csvData, sheetName: selectedValue }))
    }

    const openIPCFIleDialog = () => {
        if (_platform === platform.DESKTOP) {
            const ipcRenderer = getIPCRenderer()
            ipcRenderer &&
                ipcRenderer.send(IPCChannels.channels.CHAT_APP, {
                    event: IPCChannels.events.OPEN_FILE_DIALOG,
                    data: {},
                })
        }
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        function removeFileDescription(base64String: String) {
            // Use a regular expression to extract the base64-encoded part
            const regex = /^data:[^;]+;base64,/
            return base64String.replace(regex, "")
        }
        //const result = await ipcRenderer.invoke('open-file-dialog');
        const file = e.target.files?.[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = (event) => {
                if (event.target) {
                    if (
                        file.name.includes(".xlsx") ||
                        file.name.includes(".xls")
                    ) {
                        //const data = new Uint8Array(event.target?.result as ArrayBuffer);
                        let base64 = event.target?.result as string
                        base64 = removeFileDescription(base64)

                        const workbook = XLSX.read(base64, { type: "base64" })
                        // Extract sheet names
                        const sheetNames = workbook.SheetNames || []
                        dispatch(setSheets(sheetNames))
                        let tempType = "xls"
                        if (file.type.includes("spreadsheetml.sheet"))
                            tempType = "xlsx"
                        dispatch(
                            setCSVData({
                                fileName: file.name,
                                fileType: tempType,
                                fileData: base64,
                                sheetName: sheetNames[0] || "",
                                filePath: "",
                                
                                //filePath: null,
                            })
                        )
                        setIsExcel(true)
                    } else {
                        const dt = formatCSV(event.target.result as string)
                        dispatch(
                            setCSVData({
                                fileName: file.name,
                                fileType: file.type,
                                fileData: dt,
                                sheetName: "",
                                filePath: "",
                                
                            })
                        )
                        setIsExcel(false)
                        dispatch(setSheets([]))
                    }
                }
            }
            reader.readAsDataURL(file)
            //reader.readAsText(file)
        }
    }

    useEffect(() => {
        if (sheets.length > 0) setIsExcel(true)
    }, [sheets])
    const handleUpload = () => {
        function showSave()
        {
            setShowCheckmark(true)
            setTimeout(() => {
                setShowCheckmark(false)
                setSelectedNode(null)
            }, 600)
        }

        if (csvData && isFile) {
            handleDocumentUpload(csvData)
           showSave()
        }
        else if (!isFile && csvData)
        {
            handleDocumentUpload(csvData)
            showSave();
        }
    }

    return (
        <div
            className="h-[100%]"
            onClick={() => {
                setDisabled(true)
            }}
        >
            {showCheckmark ? (
                <div className="h-[100%] w[100%] flex justify-center items-center">
                    <Checkmark />
                </div>
            ) : (
                <>
                    <header className="flex items-center justify-center gap-2 my-2">
                        <DocumentPlusIcon className="h-8 w-8" />
                        <h1 className="font-bold text-lg">Document</h1>
                    </header>
                    <select
                        className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 sm:text-sm sm:leading-6 "
                        name="document-type-select"
                        value={isFile ? "FILE" : "GOOGLE-SHEET"}
                        onChange={selectChange}
                        id="document-type-select"
                    >
                        <option value="FILE">FILE</option>
                        <option value="GOOGLE-SHEET">GOOGLE SHEET</option>
                    </select>
                    {isFile && (
                        <>
                            {!!csvData.fileName && (
                                <div className="border-t-2 mt-6 pt-2">
                                    <p className="">
                                        Document Name:{" "}
                                        <p className="font-bold">
                                            {csvData?.fileName?.toUpperCase()}
                                        </p>
                                    </p>
                                    <p className="">
                                        Document Type:{" "}
                                        <p className="font-bold">
                                            {csvData?.fileType?.toUpperCase()}
                                        </p>
                                    </p>
                                </div>
                            )}

                            <div className="mt-4">
                                <label
                                    htmlFor="csvFile"
                                    className="block text-md leading-6 text-gray-900"
                                >
                                    Choose a Sheet: (txt, csv, xlsx, xls)
                                </label>
                                <div className="mt-2">
                                    {_platform === platform.DESKTOP ? (
                                        <button
                                            onClick={openIPCFIleDialog}
                                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 bottom-4 right-4"
                                        >
                                            Open File
                                        </button>
                                    ) : (
                                        <input
                                            type="file"
                                            id="csvFile"
                                            accept=".csv, .txt, .xlsx, .xls"
                                            className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 sm:text-sm sm:leading-6 "
                                            onChange={handleFileChange}
                                        />
                                    )}
                                    {isExcel === true && (
                                        <select
                                            className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 mt-2"
                                            name="dbSelect"
                                            value={csvData.sheetName}
                                            onChange={onSheetSelectionChange}
                                            id="sheetSelect"
                                        >
                                            {sheets.map((s) => (
                                                <option value={s}>{s}</option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {!isFile && (
                        <>
                            <label
                                htmlFor="content"
                                className="block mt-2 text-sm font-semibold leading-6 text-gray-900"
                            >
                                Google Sheet ID
                            </label>
                            <input
                                value={csvData.fileName}
                                onChange={(e)=>{
                                    const v = e.target.value;
                                    dispatch(
                                        setCSVData({
                                            ...csvData,
                                            fileName: v,
                                            
                                            fileType: 'GOOGLE_SHEET',
                                            fileData: '',
                                            filePath: v,
                                        })
                                    )
                                    
                                }}
                                placeholder="Enter ID here..."
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                            />
                            <label
                                htmlFor="content"
                                className="block mt-2 text-sm font-semibold leading-6 text-gray-900"
                            >
                                Sheet Name
                            </label>
                            <input
                            value={csvData.sheetName}
                            onChange={(e)=>{
                                const v = e.target.value;
                                dispatch(
                                    setCSVData({
                                        ...csvData,
                                        fileType: 'GOOGLE_SHEET',
                                        fileData: '',
                                        sheetName: v,
                                    })
                                )
                               
                            }}
                                placeholder="Enter sheet name here..."
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                            />
                        </>
                    )}
                    {(!userData?.gsheet_access_token && !isFile) && (
                        <>
                         <label
                                htmlFor="content"
                                className="block mt-6 text-sm leading-6 text-red"
                            >
                                Connect with google to use sheets
                            </label>
                        <button
                            onClick={() => login()}
                            className="mt-2 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50 active:bg-gray-100 w-30 px-4 h-8 flex justify-center gap-1 items-center"
                        >
                            <img src={GoogleImage} className="w-4 h-4" />
                            <p className="font-bold text-gray-600 text-md">
                                Connect Google
                            </p>
                        </button>
                        </>
                    )}
                    {(isFile || userData.gsheet_access_token) && 
                        <button
                            onClick={handleUpload}
                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 left-4 cursor-pointer"
                            disabled={csvData.fileName === ''}
                        >
                            SAVE
                        </button>
                    }
                    <button
                        onClick={() => {
                            setSelectedNode(null)
                        }}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 right-4"
                    >
                        CANCEL
                    </button>
                </>
            )}
        </div>
    )
}
