import useAppSelector from "../hooks/useAppSelector"
import { ProcessFlowType } from "../types/flow"
import { predictClient } from "./client"

// const getAllAPIKeys = () => client.get('/apikey')

const predictAPI = (body: ProcessFlowType) => {
    return predictClient.post(`/predict`, body)
}

const predictPageAPI = (body: ProcessFlowType) => 
    predictClient.post(`/pagination`, body)
// const updateAPI = (id, body) => client.put(`/apikey/${id}`, body)

// const deleteAPI = (id) => client.delete(`/apikey/${id}`)
const downloadFileAPI = (body: ProcessFlowType) =>
    predictClient.post(`/download_file`, body)

const aiConnectAPI = (body: ProcessFlowType) =>
    predictClient.get(`/heartbeat`, body)
export default {
    predictAPI,
    predictPageAPI,
    downloadFileAPI,
    aiConnectAPI,
}
