import React, { useEffect, useState } from "react"
import { CommandLineIcon } from "@heroicons/react/24/outline"
import { Node } from "reactflow"
import Checkmark from "./Checkmark"
import {
    MongoDBTitles,
    MongoDBPlaceHolders,
    MongoDBKeys,
    MSSQLKeys,
    MSSQLPlaceHolders,
    MYSQLTitles,
    MYSQLKeys,
    MSSQLTitles,
    MYSQLPlaceHolders,
    SNOWFLAKEKeys,
    SNOWFLAKEPlaceHolders,
    SNOWFLAKETitles,
    MongoDBKeysLocal,
    MongoDBPlaceHoldersLocal,
    MongoDBTitlesLocal,
    PostgresKeys,
    PostgresPlaceHolders,
    PostgresTitles
} from "../../../utils/ItemTypes"
interface DatabaseInputProps {
    selectedNode: Node<any>
    setSelectedNode: any
    handleDatabaseSave: any
    handleDatabaseAbortFocus: any
    handleDatabaseFocus: any
    setDisabled: any
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
    selectedNode,
    setSelectedNode,
    handleDatabaseSave,
    handleDatabaseFocus,
    handleDatabaseAbortFocus,
    setDisabled,
}: DatabaseInputProps) {
    const [content, setContent] = useState(selectedNode?.data)
    const [error, setError] = useState<boolean>(false)

    const [showCheckmark, setShowCheckmark] = useState(false)
    let [selectedDatabase, setSelectedDatabase] = useState<string>(
        selectedNode?.data?.dbtype || "MONGODB"
    )
    const [labelTitles, setLabelTitles] = useState<any>(MongoDBTitles)
    const [placeHolder, setPlaceHolder] = useState<any>(MongoDBPlaceHolders)

    useEffect(() => {
        //if (content)
        //handleSave(content);
    }, [content])
    useEffect(() => {
        return () => {
            setDisabled(false)
        }
    }, [])
    const makeResponseToSave = () => {
        const f1 = document.getElementById("field-1") as HTMLInputElement | null
        const f2 = document.getElementById("field-2") as HTMLInputElement | null
        const f3 = document.getElementById("field-3") as HTMLInputElement | null
        const f4 = document.getElementById("field-4") as HTMLInputElement | null
        const f5 = document.getElementById("field-5") as HTMLInputElement | null
        const f6 = document.getElementById("field-6") as HTMLInputElement | null
        const f7 = document.getElementById("field-7") as HTMLInputElement | null
        const inputValue1 = f1?.value
        const inputValue2 = f2?.value
        const inputValue3 = f3?.value
        const inputValue4 = f4?.value
        const inputValue5 = f5?.value
        const inputValue6 = f6?.value
        const inputValue7 = f7?.value

        const tempObj: any = {}

        let tempKeyObj = MongoDBKeysLocal
        if (selectedDatabase === "MSSQL" || selectedDatabase === "SQLSERVER") {
            tempKeyObj = MSSQLKeys
            selectedDatabase = "SQLSERVER"
            tempObj["Driver"] = "ODBC Driver 18 for SQL Server"
        }
        if (selectedDatabase === "MYSQL") {
            tempKeyObj = MYSQLKeys
        }
        if (selectedDatabase === "SNOWFLAKE") {
            tempKeyObj = SNOWFLAKEKeys
        }

        if (selectedDatabase === "POSTGRES"){
            tempKeyObj = PostgresKeys
        }
        tempObj[tempKeyObj.field1] = inputValue1
        tempObj[tempKeyObj.field2] = inputValue2
        tempObj[tempKeyObj.field3] = inputValue3
        tempObj[tempKeyObj.field4] = inputValue4
        if (tempKeyObj.field5) tempObj[tempKeyObj.field5] = inputValue5
        if (tempKeyObj.field6) tempObj[tempKeyObj.field6] = inputValue6
        tempObj[tempKeyObj.field7] = inputValue7
        tempObj["isCloud"] = false

        const newObj: any = {}
        newObj.value = {
            field1: inputValue1,
            field2: inputValue2,
            field3: inputValue3,
            field4: inputValue4,
            field5: inputValue5,
            field6: inputValue6,
            field7: inputValue7,
            field8: false,
        }
        newObj.conn = tempObj
        newObj.dbtype = selectedDatabase
        setContent(newObj)
        handleSave(newObj)
    }

    useEffect(() => {
        if (selectedDatabase === "MONGODB") {
            setLabelTitles(MongoDBTitlesLocal)
            setPlaceHolder(MongoDBPlaceHoldersLocal)
        }
        if (selectedDatabase === "MSSQL" || selectedDatabase === "SQLSERVER") {
            setLabelTitles(MSSQLTitles)
            setPlaceHolder(MSSQLPlaceHolders)
        }
        if (selectedDatabase === "MYSQL") {
            setLabelTitles(MYSQLTitles)
            setPlaceHolder(MYSQLPlaceHolders)
        }
        if (selectedDatabase === "SNOWFLAKE") {
            setLabelTitles(SNOWFLAKETitles)
            setPlaceHolder(SNOWFLAKEPlaceHolders)
        }
        if (selectedDatabase === "POSTGRES") {
            setLabelTitles(PostgresTitles)
            setPlaceHolder(PostgresPlaceHolders)
        }
    }, [selectedDatabase])
    const handleSave = (myContent: any) => {
        handleDatabaseSave(myContent)
        if (content) {
            setShowCheckmark(true)
            setTimeout(() => {
                setShowCheckmark(false)
                setSelectedNode(null)
            }, 600)
        }
    }

    const handleFocus = () => {
        handleDatabaseFocus()
    }
    const handleAbortFocus = () => {
        handleDatabaseAbortFocus()
    }

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    function showHidePassword() {
        setIsPasswordVisible(!isPasswordVisible)
    }

    function selectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedValue = event.target.value
        console.log(selectedValue)
        setSelectedDatabase(selectedValue)
    }
    return (
        <div
            className="h-[100%]"
            onClick={() => {
                setDisabled(true)
            }}
        >
            {showCheckmark ? (
                <div className="h-[100%] w[100%] flex justify-center items-center">
                    <Checkmark />
                </div>
            ) : (
                <>
                    <header className="flex items-center justify-center gap-2 my-2">
                        <CommandLineIcon className="h-8 w-8" />
                        <h1 className="font-bold text-lg">Database</h1>
                    </header>
                    <div className="overflow-auto h-4/5">
                        <div className="mt-2">
                            <select
                                name="dbSelect"
                                value={selectedDatabase}
                                onChange={selectChange}
                                id="dbSelect"
                            >
                                <option value="MONGODB">MongoDB</option>
                                <option value="SQLSERVER">
                                    Microsoft SQL Server
                                </option>
                                <option value="POSTGRES">Postgres</option>
                                <option value="MYSQL">MYSQL</option>
                                <option value="SNOWFLAKE">SnowFlake</option>
                            </select>
                            <label
                                htmlFor="content"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                {labelTitles.title}
                            </label>

                            <label
                                htmlFor="content"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                {labelTitles.field1}
                            </label>
                            <input
                                // onFocus={handleFocus}
                                // onBlur={handleAbortFocus}
                                name="field-1"
                                id="field-1"
                                onFocus={handleFocus}
                                onBlur={handleAbortFocus}
                                placeholder={placeHolder.field1}
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                                defaultValue={content?.value?.field1}
                                //onChange={handleContentChange}
                            />

                            <label
                                htmlFor="content"
                                className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                            >
                                {labelTitles.field2}
                            </label>
                            <input
                                onFocus={handleFocus}
                                onBlur={handleAbortFocus}
                                name="field-2"
                                id="field-2"
                                placeholder={placeHolder.field2}
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                                defaultValue={content?.value?.field2}
                            />
                            <label
                                htmlFor="content"
                                className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                            >
                                {labelTitles.field3}
                            </label>
                            <input
                                onFocus={handleFocus}
                                onBlur={handleAbortFocus}
                                name="field-3"
                                id="field-3"
                                placeholder={placeHolder.field3}
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                                defaultValue={content?.value?.field3}
                            />
                            <label
                                htmlFor="content"
                                className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                            >
                                {labelTitles.field4}
                            </label>
                            <input
                                onFocus={handleFocus}
                                onBlur={handleAbortFocus}
                                name="field-4"
                                id="field-4"
                                placeholder={placeHolder.field4}
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                                defaultValue={content?.value?.field4}
                            />
                            {labelTitles.field5 && (
                                <>
                                    <label
                                        htmlFor="content"
                                        className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                                    >
                                        {labelTitles.field5}
                                    </label>

                                    <input
                                        onFocus={handleFocus}
                                        onBlur={handleAbortFocus}
                                        name="field-5"
                                        id="field-5"
                                        placeholder={placeHolder.field5}
                                        className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                                        defaultValue={content?.value?.field5}
                                    />
                                </>
                            )}
                            {labelTitles.field6 && (
                                <>
                                    <label
                                        htmlFor="content"
                                        className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                                    >
                                        {labelTitles.field6}
                                    </label>
                                    <input
                                        onFocus={handleFocus}
                                        onBlur={handleAbortFocus}
                                        name="field-6"
                                        id="field-6"
                                        placeholder={placeHolder.field6}
                                        className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                                        defaultValue={content?.value?.field6}
                                    />
                                </>
                            )}

                            <label
                                htmlFor="content"
                                className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                            >
                                {labelTitles.field7}
                            </label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 right-0 flex items-center px-1.5 ">
                                    <input
                                        className="hidden js-password-toggle"
                                        onClick={showHidePassword}
                                        id="toggle"
                                        type="checkbox"
                                    />
                                    <label
                                        className="rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
                                        htmlFor="toggle"
                                    >
                                        {isPasswordVisible ? (
                                            <svg
                                                width="20px"
                                                height="20px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                                                    stroke="#000000"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                                                    stroke="#000000"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                width="20px"
                                                height="20px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                                                    stroke="#000000"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        )}
                                    </label>
                                </div>
                                <input
                                    onFocus={handleFocus}
                                    onBlur={handleAbortFocus}
                                    defaultValue={content?.value?.field7}
                                    className="appearance-none border-2 rounded w-full py-1.5 px-3 leading-tight border-gray-300  focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none  js-password"
                                    id="field-7"
                                    type={
                                        isPasswordVisible ? "text" : "password"
                                    }
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={makeResponseToSave}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 left-4"
                    >
                        SAVE
                    </button>
                    <button
                        onClick={() => {
                            setSelectedNode(null)
                        }}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 right-4"
                    >
                        CANCEL
                    </button>
                </>
            )}
        </div>
    )
}
