import React, { useEffect, useState } from "react"
import { CommandLineIcon } from "@heroicons/react/24/outline"
import { Node } from "reactflow"
import Checkmark from "./Checkmark"

interface PromptInputProps {
    selectedNode: Node<any>
    setSelectedNode: any
    handlePromptSave: any
    handlePromptAbortFocus: any
    handlePromptFocus: any
    setDisabled: any
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
    selectedNode,
    setSelectedNode,
    handlePromptSave,
    handlePromptFocus,
    handlePromptAbortFocus,
    setDisabled,
}: PromptInputProps) {
    const [content, setContent] = useState(selectedNode.data.content)
    const [promptName, setPromptName] = useState(selectedNode.data.name)
    if (promptName == null)
    {   
        setPromptName(`Prompt`)
        //setPromptName(`prompt-${Math.floor(Math.random() * 100000)}`)
    }
    const [error, setError] = useState<boolean>(false)
    const handlePromptContentChange = (e: any) => {
        if (content.length <= 250) {
            setContent(e.target.value)
        }
        return
    }
    const handleOnTitleChange = (e: any) => {
        setPromptName(e.target.value)
    }
    const [showCheckmark, setShowCheckmark] = useState(false)

    const handleSave = () => {
        handlePromptSave(content, promptName)
        if (content) {
            setShowCheckmark(true)
            setTimeout(() => {
                setShowCheckmark(false)
                setSelectedNode(null)
            }, 600)
        }
    }

    const handleFocus = () => {
        handlePromptFocus()
    }
    const handleAbortFocus = () => {
        handlePromptAbortFocus()
    }
    useEffect(() => {
        if (content.length > 250) {
            setError(true)
            setContent((prev: string) => {
                return prev.slice(0, 250)
            })
            setTimeout(() => {
                setError(false)
            }, 500)
        }
    }, [content])

    useEffect(() => {
        return () => {
            setDisabled(false)
            // Code to run when component is unmounted
            console.log(
                "Component is unmounted. Clean up resources or perform necessary actions."
            )
        }
    }, [setDisabled])
    return (
        <div
            className="h-[100%] z-110"
            onClick={() => {
                setDisabled(true)
            }}
        >
            {showCheckmark ? (
                <div className="h-[100%] w[100%] flex justify-center items-center">
                    <Checkmark />
                </div>
            ) : (
                <>
                    <header className="flex items-center justify-center gap-2 my-2">
                        <CommandLineIcon className="h-8 w-8" />
                        <h1 className="font-bold text-lg">Prompt</h1>
                    </header>
                    <div>
                        <label
                            htmlFor="content"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Title
                        </label>
                        <input
                            // onFocus={handleFocus}
                            // onBlur={handleAbortFocus}
                            name="field-1"
                            id="field-1"
                            maxLength={20}
                            onFocus={handleFocus}
                            onBlur={handleAbortFocus}
                            placeholder="Enter Prompt Name"
                            className="p-2 mb-1.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                            defaultValue={promptName}
                            onChange={handleOnTitleChange}
                        />
                        <label
                            htmlFor="content"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Add your instructions
                        </label>
                        <div className="mt-2">
                            <textarea
                                onFocus={handleFocus}
                                onBlur={handleAbortFocus}
                                rows={4}
                                name="content"
                                id="content"
                                placeholder="Example: Map two tables based on ID column"
                                className="p-2 h-40 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring- focus:bg-gray-50  sm:text-sm sm:leading-6 resize-none "
                                value={content}
                                onChange={handlePromptContentChange}
                            />
                            <div
                                className={`w-[100%] h-6 flex items-center text-gray-500 ${
                                    content.length > 200
                                        ? "text-orange-500"
                                        : ""
                                }`}
                            >
                                <p
                                    className={`${
                                        error ? "text-sm" : `text-xs`
                                    } ${
                                        error || content.length === 250
                                            ? "text-red-700"
                                            : ""
                                    }`}
                                >
                                    {content.length}/250
                                </p>
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={handleSave}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 left-4"
                    >
                        SAVE
                    </button>
                    <button
                        onClick={() => {
                            setSelectedNode(null)
                        }}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 absolute bottom-4 right-4"
                    >
                        CANCEL
                    </button>
                </>
            )}
        </div>
    )
}
