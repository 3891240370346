// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
      width: 100%;
      /* Adjust width as needed */
      height: 10px;
      /* Adjust height as needed */
      background-color: #f3f3f3;
      color: dodgerblue;
      margin: 0;
      /* Background color of the progress bar container */
      border-radius: 4px;
}

.progress::-webkit-progress-bar {
      background-color: #f3f3f3;
      color: dodgerblue;
      border-radius: 4px;
      /* Background color of the progress bar container for WebKit browsers */
}

.progress::-webkit-progress-value {
      background-color: dodgerblue;
      color: dodgerblue;
      border-radius: 4px;
      /* Color of the progress bar itself for WebKit browsers */
}

.progress::-moz-progress-bar {
      background-color: #4caf50;
      color: dodgerblue;
      /* Color of the progress bar itself for Firefox */
}`, "",{"version":3,"sources":["webpack://./src/ui-components/common/SideBarComponent.css"],"names":[],"mappings":"AAAA;MACM,WAAW;MACX,2BAA2B;MAC3B,YAAY;MACZ,4BAA4B;MAC5B,yBAAyB;MACzB,iBAAiB;MACjB,SAAS;MACT,mDAAmD;MACnD,kBAAkB;AACxB;;AAEA;MACM,yBAAyB;MACzB,iBAAiB;MACjB,kBAAkB;MAClB,uEAAuE;AAC7E;;AAEA;MACM,4BAA4B;MAC5B,iBAAiB;MACjB,kBAAkB;MAClB,yDAAyD;AAC/D;;AAEA;MACM,yBAAyB;MACzB,iBAAiB;MACjB,iDAAiD;AACvD","sourcesContent":[".progress {\n      width: 100%;\n      /* Adjust width as needed */\n      height: 10px;\n      /* Adjust height as needed */\n      background-color: #f3f3f3;\n      color: dodgerblue;\n      margin: 0;\n      /* Background color of the progress bar container */\n      border-radius: 4px;\n}\n\n.progress::-webkit-progress-bar {\n      background-color: #f3f3f3;\n      color: dodgerblue;\n      border-radius: 4px;\n      /* Background color of the progress bar container for WebKit browsers */\n}\n\n.progress::-webkit-progress-value {\n      background-color: dodgerblue;\n      color: dodgerblue;\n      border-radius: 4px;\n      /* Color of the progress bar itself for WebKit browsers */\n}\n\n.progress::-moz-progress-bar {\n      background-color: #4caf50;\n      color: dodgerblue;\n      /* Color of the progress bar itself for Firefox */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
