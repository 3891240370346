import { Fragment, useState } from "react"
import { Dialog, Menu, Transition } from "@headlessui/react"
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline"
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid"
import { Outlet, useNavigate } from "react-router-dom"
import useAppDispatch from "../../hooks/useAppDispatch"
import { logoutUser } from "../../features/auth/authSlice"
import { useDispatch } from "react-redux"

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

interface Props {
    sidebarOpen: boolean
    setSidebarOpen: any
}
const userNavigation = [
    { name: "Your profile", href: "#" },
    { name: "Sign out", href: "#" },
]

export default function NavbarComponent({
    sidebarOpen,
    setSidebarOpen,
}: Props) {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    return (
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
                className="h-6 w-px bg-gray-200 lg:hidden"
                aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form
                    className="relative flex flex-1"
                    action="#"
                    method="GET"
                ></form>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                    {/* Separator */}
                    <div
                        className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                        aria-hidden="true"
                    />

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                            {/* <span className="sr-only">Open user menu</span>

                            <span className="hidden lg:flex lg:items-center">
                                <span
                                    className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                    aria-hidden="true"
                                >
                                    Options
                                </span>
                                <ChevronDownIcon
                                    className="ml-2 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span> */}
                            <button
                                            onClick={() => {
                                                dispatch(logoutUser())
                                                navigate("/login")
                                            }}
                                            className={classNames(
                                                "bg-gray-50",
                                                "block px-3 py-1 text-sm leading-6 text-gray-900 w-[100%] text-left"
                                            )}
                                        >
                                            Sign Out
                                        </button>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active ? "bg-gray-50" : "",
                                                "block px-3 py-1 text-sm leading-6 text-gray-900 w-[100%] text-left"
                                            )}
                                        >
                                            Settings
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => {
                                                dispatch(logoutUser())
                                                navigate("/login")
                                            }}
                                            className={classNames(
                                                active ? "bg-gray-50" : "",
                                                "block px-3 py-1 text-sm leading-6 text-gray-900 w-[100%] text-left"
                                            )}
                                        >
                                            Sign Out
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    )
}
