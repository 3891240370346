import React, { createContext, useState, ReactNode } from "react"
import IPCChannels from "../config/constants"
import { setCSVData, setSheets } from "../features/nodesSlice"
import useAppDispatch from "../hooks/useAppDispatch"
import { IpcRenderer } from "electron"
import { useNavigate } from "react-router-dom"

// Define the shape of the context data
interface MyContextType {
    value: string
    setValue: (value: string) => void
}

// Create the context with a default value
const IPCContext = createContext<MyContextType | undefined>(undefined)

// Create a provider component
const IPCProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [value, setValue] = useState("")
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    function ipcListener() {
        try {
            let ipcRenderer : IpcRenderer | null= null
            const electron = window.require("electron")

            ipcRenderer = electron.ipcRenderer
            ipcRenderer.on(
                IPCChannels.channels.CHAT_APP,
                (event: any, arg: any) => {
                    console.log(arg.event)
                    const data = JSON.parse(arg)
                    if (data.event === IPCChannels.events.OPEN_FILE_DIALOG) {
                        let type = data.data.fileName.split('.')[1];
                        if (type === 'csv' || type === 'txt')
                            type = "text/csv";
                        dispatch(setSheets(data.data.sheets));
                         dispatch( setCSVData({
                            fileName: data.data.fileName,
                            fileType: type,
                            fileData: null,
                            sheetName: data.data.sheets[0],
                            filePath: data.data.path,
                        }))
                    }
                }
            )

        } catch (e) {
            console.log(e)
        }
    }
    ipcListener()

    return (
        <IPCContext.Provider value={{ value, setValue }}>
            {children}
        </IPCContext.Provider>
    )
}

export { IPCContext, IPCProvider }
