// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CanvasNodeInput.css */
.node-input-container {
    position: absolute;
    right: 0;
    top: 80px;
    z-index: 50;
    width: 300px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px 0 0 8px;
    padding: 10px 15px;
    transform: translateY(-20px);
    box-shadow: 15px 15px 15px rgb(217, 217, 217);
    transition:
        opacity 0.15s ease-in-out,
        transform 0.15s ease-in-out;
    opacity: 1;
    transform: translateX(0);
}

.node-input-container.hidden-container {
    opacity: 0;
    /* transform: translateX(200px); */
    pointer-events: none; /* Prevent interaction when hidden */
}
`, "",{"version":3,"sources":["webpack://./src/views/canvas/CanvasNodeInput.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,0BAA0B;IAC1B,kBAAkB;IAElB,4BAA4B;IAC5B,6CAA6C;IAC7C;;mCAE+B;IAC/B,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,kCAAkC;IAClC,oBAAoB,EAAE,oCAAoC;AAC9D","sourcesContent":["/* CanvasNodeInput.css */\r\n.node-input-container {\r\n    position: absolute;\r\n    right: 0;\r\n    top: 80px;\r\n    z-index: 50;\r\n    width: 300px;\r\n    background-color: rgb(255, 255, 255);\r\n    border-radius: 8px 0 0 8px;\r\n    padding: 10px 15px;\r\n    opacity: 0;\r\n    transform: translateY(-20px);\r\n    box-shadow: 15px 15px 15px rgb(217, 217, 217);\r\n    transition:\r\n        opacity 0.15s ease-in-out,\r\n        transform 0.15s ease-in-out;\r\n    opacity: 1;\r\n    transform: translateX(0);\r\n}\r\n\r\n.node-input-container.hidden-container {\r\n    opacity: 0;\r\n    /* transform: translateX(200px); */\r\n    pointer-events: none; /* Prevent interaction when hidden */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
