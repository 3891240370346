
let ipcRenderer: any | null = null;

export function getIPCRenderer()
{
    try {
        const electron = window.require('electron');
        ipcRenderer = electron.ipcRenderer;
        return ipcRenderer;
    } catch (e) {
        console.error('Failed to load electron ipcRenderer:', e);
    }
}

