// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flow-row-style{
    transition: 0.3s;
}

.flow-row-style:hover{
      background-color: rgba(255, 166, 0, 0.173);
}`, "",{"version":3,"sources":["webpack://./src/style/flow-list.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;MACM,0CAA0C;AAChD","sourcesContent":[".flow-row-style{\n    transition: 0.3s;\n}\n\n.flow-row-style:hover{\n      background-color: rgba(255, 166, 0, 0.173);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
