// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-container {
  width: 100%;
  background-color: white;
  border-bottom: 2px solid;
  overflow-x: scroll; /* Show horizontal scrollbar */
  scroll-behavior: smooth; /* Optional: Smooth scrolling behavior */
  display: flex; /* Optional: To align items horizontally */
  align-items: center; /* Optional: To vertically center items */
  padding: 0 8px; /* Optional: Add padding for content */
}`, "",{"version":3,"sources":["webpack://./src/views/canvas/style/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,wBAAwB;EACxB,kBAAkB,EAAE,8BAA8B;EAClD,uBAAuB,EAAE,wCAAwC;EACjE,aAAa,EAAE,0CAA0C;EACzD,mBAAmB,EAAE,yCAAyC;EAC9D,cAAc,EAAE,sCAAsC;AACxD","sourcesContent":[".scroll-container {\n  width: 100%;\n  background-color: white;\n  border-bottom: 2px solid;\n  overflow-x: scroll; /* Show horizontal scrollbar */\n  scroll-behavior: smooth; /* Optional: Smooth scrolling behavior */\n  display: flex; /* Optional: To align items horizontally */\n  align-items: center; /* Optional: To vertically center items */\n  padding: 0 8px; /* Optional: Add padding for content */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
