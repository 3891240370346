// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animated-check {
    height: 7em;
    width: 7em;
}

.animated-check path {
    fill: none;
    stroke: #539d72;
    stroke-width: 4;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 0.4s linear forwards;
    stroke-linecap: round;
    stroke-linejoin: round;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/ui-components/canvas/input/Checkmark.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,UAAU;IACV,eAAe;IACf,eAAe;IACf,oBAAoB;IACpB,qBAAqB;IACrB,oCAAoC;IACpC,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".wrapper {\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.animated-check {\n    height: 7em;\n    width: 7em;\n}\n\n.animated-check path {\n    fill: none;\n    stroke: #539d72;\n    stroke-width: 4;\n    stroke-dasharray: 23;\n    stroke-dashoffset: 23;\n    animation: draw 0.4s linear forwards;\n    stroke-linecap: round;\n    stroke-linejoin: round;\n}\n\n@keyframes draw {\n    to {\n        stroke-dashoffset: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
