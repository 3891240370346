import { Fragment, useEffect } from "react"
import { Menu, Transition } from "@headlessui/react"
import {
    ArchiveBoxIcon,
    ArrowRightCircleIcon,
    ChevronDownIcon,
    DocumentDuplicateIcon,
    HeartIcon,
    PencilSquareIcon,
    TrashIcon,
    UserPlusIcon,
} from "@heroicons/react/20/solid"
import { ProcessFlowType } from "../../types/flow"
import { Link, useNavigate } from "react-router-dom"
import useAppDispatch from "../../hooks/useAppDispatch"
import { showPrompt } from "../../features/promptDescSlice"
import useApi from "../../hooks/useApi"
import flow, { getOneFlowRequest } from "../../api/flow"
import useAppSelector from "../../hooks/useAppSelector"

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

interface OptionsButtonProps {
    processFlow: ProcessFlowType
    handleEditDescription: any
    descriptionPrompt: boolean
    setModalOpen: (open: boolean) => void
}

export default function OptionsButton({
    processFlow,
    descriptionPrompt,
    handleEditDescription,
    setModalOpen,
}: OptionsButtonProps) {
    const createFlowApi = useApi(flow.createFlow)
    const allProcess = useAppSelector(stat=> stat.processReducer.allProcess);
    const token = useAppSelector(stat=>stat.auth.token);
function removeSuffix(str: string): string {
    const regex = /_(\d+)$/;
    const match = str.match(regex);
    
    if (match) {
        const suffix = match[0];
        const stringWithoutSuffix = str.slice(0, -suffix.length);
        return stringWithoutSuffix;
    }
    
    return str;
}
    function generateNewTitle(existingTitles: ProcessFlowType[] | null, inputTitle: string): string | null {
        if (existingTitles == null) return null;
        inputTitle = removeSuffix(inputTitle);
        existingTitles = existingTitles.filter(ex=> ex.name.includes(inputTitle));
        const regex = /_(\d+)$/;
        const suffixes: number[] = existingTitles
            .map(file => {
                const match = file.name.match(regex);
                return match ? parseInt(match[1]) : 0;
            });
    
        const maxSuffix = Math.max(...suffixes);
    
        if (inputTitle.includes('_')) {
            const [name, existingSuffix] = inputTitle.split('_');
            if (!isNaN(Number(existingSuffix))) {
                return `${name}_${maxSuffix + 1}`;
            }
        }
    
        return `${inputTitle}_${maxSuffix + 1}`;
    }
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const handleDescription = () => {
        if (descriptionPrompt == true)
        {
            dispatch(showPrompt({process: processFlow}))
        }
        else
        {
            handleEditDescription();
        }
    }
    const handleDuplicate =  async () => {

        let response = null;
        if (token)
            {
                response = await getOneFlowRequest(processFlow.id, token);
                console.log(response?.data?.data)
            }

        if (response?.data?.data)
            {
                const newName = generateNewTitle(allProcess,processFlow.name);
                createFlowApi.request(token, {
                     ...response?.data?.data, name: newName, isSaved: true
                 })
            }

    }
    useEffect(()=>{
        if (createFlowApi.data)
        {
            window.location.reload();
        }
    },[createFlowApi.data])
    return (
        <Menu as="div" className="relative inline-block text-left z-1">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Options
                    <ChevronDownIcon
                        className="-mr-1 h-4 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={`/canvas/${processFlow.id}`}
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                    )}
                                >
                                    <ArrowRightCircleIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Open
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={handleDescription}
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm w-[100%]"
                                    )}
                                >
                                    <PencilSquareIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Description
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={handleDuplicate}
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                    )}
                                >
                                    <DocumentDuplicateIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Duplicate
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="#"
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                    )}
                                >
                                    <ArchiveBoxIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Archive (Enterprise Only)
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="#"
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                    )}
                                >
                                    <UserPlusIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Share (Enterprise Only)
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="#"
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                    )}
                                >
                                    <HeartIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Add to favorites (Enterprise Only)
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => setModalOpen(true)}
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm w-[100%]"
                                    )}
                                >
                                    <TrashIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Delete
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
