import React, { useCallback, useState } from "react"
import { useReactFlow } from "reactflow"
import "./ContextMenu.css"
import { generateRandomId } from "../../utils/misc"
import DuplicateIcon from "../../assets/imageJS/DuplicateIcon"
import DeleteIconX from "../../assets/imageJS/DeleteIcon"
import PreviewImage from "../../assets/imageJS/PreviewImage"
import { ProcessFlowType } from "../../types/flow"
import { Snackbar } from "@mui/material"
import { checkIfPromptSelected } from "./utils/misc"
interface ContextMenuProps {
    id: string
    top?: number
    left?: number
    right?: number
    bottom?: number
    type: string
    nodeType: string
    processFlow: ProcessFlowType
    handleRun: any
}

const ContextMenu: React.FC<ContextMenuProps> = ({
    id,
    top,
    left,
    right,
    bottom,
    type,
    nodeType,
    processFlow,
    handleRun,
    ...props
}) => {
    const { getNode, setNodes, addNodes, setEdges } = useReactFlow()
    const [snackbar, setSnackbar] = useState<boolean>(false)
    const duplicateNode = useCallback(() => {
        const node = getNode(id)
        if (node) {
            const position = {
                x: node.position.x + 50,
                y: node.position.y + 50,
            }

            addNodes({ ...node, id: generateRandomId(16), position })
        }
    }, [id, getNode, addNodes])

    const deleteNode = useCallback(() => {
        setNodes((nodes) => nodes.filter((node) => node.id !== id))
        setEdges((edges) => edges.filter((edge) => edge.source !== id))
    }, [id, setNodes, setEdges])
    const deleteEdge = useCallback(() => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id))
    }, [id, setEdges])

    return (
        <div
            style={{ top, left, right, bottom, borderRadius: "5px" }}
            className="context-menu"
            {...props}
        >
            {type === "node" && (
                <>
                    {nodeType === "prompt" && (
                        <div
                            className="flex items-center p-2 hover:bg-gray-100"
                            onClick={() => {
                                handleRun(true)
                            }}
                        >
                            <PreviewImage
                                color="#000000"
                                width="20px"
                                height="20px"
                            />
                            <p className="p-1 text-xs">preview</p>
                        </div>
                    )}
                    <div
                        className="flex items-center p-2 hover:bg-gray-100 "
                        onClick={duplicateNode}
                    >
                        <DuplicateIcon />
                        <p className="p-1 text-xs">duplicate</p>
                    </div>

                    <div
                        className="flex items-center p-2 hover:bg-gray-100"
                        onClick={deleteNode}
                    >
                        <DeleteIconX />
                        <p className="p-1 text-xs">delete</p>
                    </div>
                </>
            )}
            {type === "edge" && (
                <div
                    className="flex items-center p-2 hover:bg-gray-100"
                    onClick={deleteEdge}
                >
                    <DeleteIconX />
                    <p className="p-1 text-xs">delete</p>
                </div>
            )}
        </div>
    )
}

export default ContextMenu
