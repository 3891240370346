import user from "../../../api/user"
import { UserData } from "../../../types/auth"
import { ProcessFlowType } from "../../../types/flow"

export function predictAPIPayloadManipulate(
    flow: ProcessFlowType,
    previewMode: boolean,
    balance: number
) {
    const tempFlow: ProcessFlowType = JSON.parse(JSON.stringify(flow))

    if (tempFlow.data?.nodes) {
        for (const f of tempFlow.data?.nodes) {
            if (previewMode === false) f.selected = false
        }
    }
    tempFlow.userTokens = balance
    return tempFlow
}

export function validateProcessFlow(flow: ProcessFlowType) {
    const tempFlow: ProcessFlowType = JSON.parse(JSON.stringify(flow))
    let error = false
    if (tempFlow.data?.nodes) {
        for (const f of tempFlow.data?.nodes) {
            if (f.type === "prompt") {
                error = true
                for (const edge of tempFlow.data.edges) {
                    if (f.id === edge.target) {
                        error = false
                        break
                    }
                }
            }
            if (f.type !== "prompt") {
                error = true
                for (const edge of tempFlow.data.edges) {
                    if (f.id === edge.source) {
                        error = false
                        break
                    }
                }
            }
            if (error === true) break
        }
    }
    return error
}

export async function checkForGoogleSheet(
    flow: ProcessFlowType,
    token: string,
    access_token: string
) {
    const tempFlow: ProcessFlowType = JSON.parse(JSON.stringify(flow))
    let hasSheet = false
    let hasError = false
    if (tempFlow.data?.nodes) {
        for (const f of tempFlow.data?.nodes) {
            if (f.type === "document") {
                if (f.data.fileType === "GOOGLE_SHEET") {
                    hasSheet = true
                    break
                }
            }
            //if (error === true) break;
        }
    }
    if (hasSheet) {
        try {
            const response = await user.checkUserAccessToken(
                token,
                access_token
            )
            console.log(response)
        } catch (e) {
            hasError = true
        }
    }

    return {notSignedIn: hasError, hasSheet}
}

export function makeNodeSelected(flow: ProcessFlowType, id: string) {
    const tempFlow: ProcessFlowType = JSON.parse(JSON.stringify(flow))

    if (tempFlow.data?.nodes) {
        for (const f of tempFlow.data?.nodes) {
            if (f.id === id) {
                f.selected = true
                break
            }
        }
    }
    return tempFlow
}

export function checkIfPromptSelected(flow: ProcessFlowType) {
    const tempFlow: ProcessFlowType = JSON.parse(JSON.stringify(flow))
    let promptSelected: boolean = false
    if (tempFlow.data?.nodes) {
        for (const f of tempFlow.data?.nodes) {
            if (f.type === "prompt" && f.selected === true)
                promptSelected = true
        }
    }
    console.log(promptSelected)
    return promptSelected
}

export function updateBalance(data: any, userData: UserData) {
    let balance = 0
    let sum = data.usage

    if (userData) {
        balance = userData.balance || 0
        const d = {
            ...userData,
            balance: balance - sum,
        }
        window.localStorage.setItem("userData", JSON.stringify(d))
        return balance - sum
    }
    return 0
}
