module.exports = {
    channels: {
          CHAT_APP: 'open_chat_app',
    },

    events: {
        OPEN_FILE_DIALOG: 'open_file_dialog',
        OPEN_CHAT_APP: 'open_chat_app',
        GOOGLE_AUTH: 'handle-auth-google'
    }
};

