import { apiClient } from "./client"

interface requestData {
    nodeId: string,
    userId: string,
    type: string
}

export const downloadResultAPI = async (token: string, data: requestData) => {
    return await apiClient.post(`/v1/utils/`, data,{
        headers: {
            Authorization: `Bearer ${token}`,
             responseType: 'blob'
        },
    })

}