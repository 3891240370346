// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    text-wrap: nowrap;
    opacity: 0;
    transition: opacity 0.2s;
}

.custom-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/views/canvas/CanvasSidebar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,UAAU;IACV,2BAA2B;IAC3B,iBAAiB;IACjB,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;IACnB,UAAU;AACd","sourcesContent":[".custom-tooltip {\n    position: relative;\n    display: inline-block;\n    cursor: pointer;\n}\n\n.tooltip-text {\n    visibility: hidden;\n    background-color: #333;\n    color: #fff;\n    text-align: center;\n    border-radius: 4px;\n    padding: 4px 8px;\n    position: absolute;\n    z-index: 1;\n    top: 50%;\n    left: 100%;\n    transform: translateY(-50%);\n    text-wrap: nowrap;\n    opacity: 0;\n    transition: opacity 0.2s;\n}\n\n.custom-tooltip:hover .tooltip-text {\n    visibility: visible;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
