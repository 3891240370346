import React, { useEffect, useRef, useState } from "react"
import Papa from "papaparse"
import { CSVLink } from "react-csv"
import LoadingSpinner from "../../ui-components/common/LoadingSpinner"
import { ProcessFlowType } from "../../types/flow"
import predict from "../../api/predict"
import useApi from "../../hooks/useApi"
import useAppSelector from "../../hooks/useAppSelector"
import "./../../../src/views/canvas/style/style.css"
import { setPage } from "../../features/flowSlice"
import useAppDispatch from "../../hooks/useAppDispatch"
interface CSVTableProps {
    csvData: any
    loading: boolean
    isOpen: boolean
    previewError: string
    processFlow: ProcessFlowType
    nodeId: string
}
function CSVTable({
    csvData,
    loading,
    isOpen,
    previewError,
    processFlow,
    nodeId,
}: CSVTableProps) {

    const dispatch = useAppDispatch();
    const [tableData, setTableData] = useState<any[]>([])
    const [bottomPosition, setBottomPositon] = useState<any>(0)
    const predictPageAPI = useApi(predict.predictPageAPI)

    const [localCSVData, setLocalCSVData] = useState<any>()
    const predictSuccess = useAppSelector(
        (state) => state.processReducer.predictSuccess
    )
    const page = useAppSelector(state => state.processReducer.page);
    //console.log('CSV TABLE INIT')
    // Use useEffect to parse the CSV data when it's available

    useEffect(() => {
        if (localCSVData) {
            const parsed = Papa.parse(localCSVData, {
                header: true, // Treat the first row as headers
                dynamicTyping: true, // Automatically convert numeric values
                skipEmptyLines: true, // Skip empty lines in the CSV
            })
            console.log("CSV Table loaded")
            setTableData(parsed.data)
        }
    }, [localCSVData])

    useEffect(() => {
        setLocalCSVData(csvData)
    }, [csvData])
    useEffect(() => {
        console.log("PRedict success " + predictSuccess + " "+page)
        
        if (processFlow.id != "" && predictSuccess) {
            console.log("Predict Page API called")
            predictPageAPI.request({
                userId: processFlow.userId,
                nodeId,
                pageNumber: page,
                pageLimit: "10",
            })
        }
    }, [page])
    useEffect(() => {
        if (predictPageAPI.data && predictPageAPI?.data?.data) {
            console.log('page data')
            let lines = predictPageAPI.data.data.split("\n")

            // Remove the first line
            lines.shift()

            // Join the remaining lines back together
            let resultString = lines.join("\n")

            setLocalCSVData(localCSVData + "\n" + resultString)

            //console.log(predictPageAPI.data)
        }
    }, [predictPageAPI.data])

    const scrollableDivRef = useRef<HTMLDivElement>(null)
    const [lastScrollTop, setLastScrollTop] = useState(0)

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current) {
                const currentScrollTop = scrollableDivRef.current.scrollTop

                if (currentScrollTop !== lastScrollTop) {
                    // Vertical scroll detected
                    //console.log("Vertical scroll detected", currentScrollTop)
                    onVerticalScroll()
                }

                setLastScrollTop(currentScrollTop)
            }
        }

        const div = scrollableDivRef.current
        if (div) {
            div.addEventListener("scroll", handleScroll)
        }

        // Clean up event listener on component unmount
        return () => {
            if (div) {
                div.removeEventListener("scroll", handleScroll)
            }
        }
    }, [lastScrollTop])

    const onVerticalScroll = () => {
        // Your function code here
        const container = scrollableDivRef.current
        if (!container) {
            console.log("Container null")
            return
        }
        //console.log(`Container H ${container.scrollHeight} - ${container.scrollTop} - ${container.clientHeight}`)
        // Check if the scroll position is at the bottom of the container
        if (
            container.scrollHeight - container.scrollTop - 1 <=
            container.clientHeight
        ) {
            // Trigger your desired action here
            if (scrollableDivRef.current) {
                const currentScrollTop = scrollableDivRef.current.scrollTop
                
                if (bottomPosition !== currentScrollTop) {
                    console.log(bottomPosition, currentScrollTop, " "+page)
                    setBottomPositon(currentScrollTop)
                    dispatch(setPage(page + 1))
                }
            }
            console.log("Reached the bottom of the container!")
        }
    }

    return (
        <div
            //onScroll={handleScroll}
            className={`h-[95%] w-[100%] overflow-auto bg-gray-100 px-4 pt-1 ${
                isOpen ? "" : "hidden"
            }`}
        >
            {loading ? (
                <div className="h-[100%] w-[100%] flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div
                    ref={scrollableDivRef}
                    className={`overflow-x-auto h-[100%] mx-auto p-4`}
                >
                    <table className="min-w-full border-collapse border border-gray-300">
                        <thead>
                            <tr>
                                {tableData.length > 0 &&
                                    Object.keys(tableData[0]).map((header) => (
                                        <th
                                            key={header}
                                            className="px-6 py-3 bg-gray-200 text-left text-md font-bold text-gray-700 uppercase tracking-wider"
                                        >
                                            {header.replaceAll('"', "")}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {Object.values(row).map(
                                        (cell: any, cellIndex) => (
                                            <td
                                                key={cellIndex}
                                                className={`px-6 py-4 whitespace-nowrap text-sm ${
                                                    cellIndex % 2 === 0
                                                        ? "bg-white"
                                                        : "bg-gray-100"
                                                }`}
                                            >
                                                {cell}
                                            </td>
                                        )
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {previewError ? previewError : ""}
        </div>
    )
}

export default CSVTable
