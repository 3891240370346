import { AxiosResponse } from "axios"
import APIResponse from "../types/response"
import { apiClient } from "./client"
import IPlans from "../types/plan"

const getAllPlans = (
    token: string
): Promise<AxiosResponse<APIResponse<IPlans>>> =>
    apiClient.get(`/v1/plans/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

export default {
    getAllPlans
}
