import { configureStore } from "@reduxjs/toolkit"
import authReducer from "../features/auth/authSlice"
import promptDescReducer from "../features/promptDescSlice"
import processReducer from "../features/flowSlice"
import nodesReducer from "../features/nodesSlice"
export const store = configureStore({
    reducer: {
        auth: authReducer,
        promptDescReducer: promptDescReducer,
        processReducer: processReducer,
        nodesReducer
    },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
