import { Link } from "react-router-dom"
import DatabaseImage from "../../assets/imageJS/DataBaseImage"
import "./../../style/misc.css"
import PromptImage from "../../assets/imageJS/PromptImage"
import document from "../../assets/images/document.png"
import DocumentImage from "../../assets/imageJS/DocumentImage"
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline"
import OptionsButton from "./OptionsButton"
import { useEffect, useState } from "react"
import DeleteProcessFlowModal from "../processflows/DeleteFlowModal"
import { ProcessFlowType } from "../../types/flow"
import useApi from "../../hooks/useApi"
import flow from "../../api/flow"
import useAppSelector from "../../hooks/useAppSelector"
import PredictiveImage from "../../assets/imageJS/PredictiveImage"
interface Props {
    data: any
    index: any
    processFlow: ProcessFlowType
}
export default function ItemCard({ data, index, processFlow }: Props) {
    const [modalOpen, setModalOpen] = useState(false)
    const updateFlowApi = useApi(flow.updateFlow)
    const deleteFlowApi = useApi(flow.deleteFlow)
    const [isEdit, setIsEdit] = useState(false)
    const token = useAppSelector((state) => state.auth.token)
    const handleDeleteFlow = () => {
        deleteFlowApi.request(token, processFlow.id)
    }
    useEffect(() => {
        if (deleteFlowApi.data) {
            window.location.reload()
        }
        
    }, [deleteFlowApi.data])
    let databaseExists = false
    let documentExists = false
    let promptExists = false
    let predictiveExists = false
    processFlow.data?.nodes.map((n) => {
        if (n.type == "document") documentExists = true
        if (n.type == "database") databaseExists = true
        if (n.type == "prompt") promptExists = true
        if (n.type == "predictive") predictiveExists = true
    })
    return (
        <div key={index} className="p-4 min-w-80 lg:w-1/4 md:w-1/3 sm:w-1/2">
            {/* Replace the following with your tile content */}

            <div className="flow-card">
                <Link to={`/canvas/${data.id}`}>
                    <p className="flex text-xl font-semibold text-wrap text-zinc-700 ml-3 mr-3 mt-3">
                        {data.name}
                    </p>
                    <p className="flex font-normal text-wrap text-zinc-700 ml-3 mr-5">
                        {data.description}
                    </p>
                </Link>
                <div className="flex absolute bottom-0 w-full flex-col">
                    <div className="flex p-3 justify-between w-full">
                        <div className="flex">
                            {documentExists && <img className="h-7 w-auto" src={document} alt="" />}
                            {promptExists && <PromptImage />}
                            {databaseExists && <DatabaseImage />}
                            {predictiveExists && <PredictiveImage />}
                        </div>
                        <div className="flex item-end">
                            <OptionsButton
                                descriptionPrompt={true}
                                processFlow={processFlow}
                                handleEditDescription={() => setIsEdit(true)}
                                setModalOpen={setModalOpen}
                            />
                            <DeleteProcessFlowModal
                                open={modalOpen}
                                setOpen={setModalOpen}
                                handleDeleteFlow={handleDeleteFlow}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
