import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import useApi from "../../hooks/useApi"
import flow from "../../api/flow"
import predict from "../../api/predict"
import useAppSelector from "../../hooks/useAppSelector"
import { ProcessFlowType } from "../../types/flow"
import "./../../style/misc.css"
import ProgressBar from "../../ui-components/common/ProgressBar"
import ProcessflowsTable from "../../ui-components/cardandlists/ProcessflowsTable"
import { PlusIcon } from "@heroicons/react/20/solid"
import { Squares2X2Icon } from "@heroicons/react/20/solid"
import { ListBulletIcon } from "@heroicons/react/20/solid"
import ProcessflowsCardList from "../../ui-components/cardandlists/ProcessflowsCardList"
import useAppDispatch from "../../hooks/useAppDispatch"
import { setAllProcess } from "../../features/flowSlice"
import plans from "../../api/plans"
import { setPlans } from "../../features/auth/authSlice"
import IPlans from "../../types/plan"
import axios from "axios"
import fileDownload from "js-file-download"
import { IPCEventType } from "../../types/ipc"
import { Bounce, toast } from "react-toastify"
import { Snackbar } from "@mui/material"
interface Props {}

interface ErrorStatusType {
    status: boolean,
    message: string
}

export default function ProcessFlowPage({}: Props) {
    const token = useAppSelector((state) => state.auth.token)
    const [pyloader, setPyloader] = useState(false)
    const dispatch = useAppDispatch()
    const [toggleItemView, setToggleItemView] = useState(false)
 
    const navigate = useNavigate()

    const [processFlows, setProcessFlows] = useState<ProcessFlowType[]>([])
    const plansAPI = useApi(plans.getAllPlans)
    const allProcess = useAppSelector(
        (state) => state.processReducer.allProcess
    )
    const [savedProcessCount, setSavedProcessCount] = useState<number>(0)
    useEffect(() => {
        let count = 0
        if (allProcess && allProcess.length) {
            allProcess.map((p) => {
                if (p.isSaved === true) count++
            })

            setSavedProcessCount(count)
        }
    }, [allProcess])
    const allPlans = useAppSelector((state) => state.auth.plans)
    const userData = useAppSelector((state) => state.auth.userData)
    const [userPlan, setUserPlan] = useState<IPlans>()
    const [processValidationError, setProcessValidationError] =
    useState<ErrorStatusType>({
        status: false,
        message: ""
    })
    useEffect(() => {
        if (allPlans && allPlans.length > 0) {
            for (const p of allPlans) {
                console.log(p)
                if (userData.userTypeName === p.userTypeName) {
                    setUserPlan(p)
                }
            }
        }
    }, [allPlans])
    const handleAddProcessFlow = () => {
        console.log(savedProcessCount + "--" + userPlan?.flows)
        if (savedProcessCount >= (userPlan?.flows || 0)) {
            setProcessValidationError({status: true, message: "Max Number of flow reached, please update the plan"});
        } else {
            navigate("/canvas")
        }
    }
    const flowApi = useApi(flow.getAllUserFlows)
    const aiConnectAPI = useApi(predict.aiConnectAPI)
    useEffect(() => {
        setPyloader(true)
        plansAPI.request(token)
        aiConnectAPI.request()
    }, [])

    useEffect(() => {
        if (plansAPI.data?.data) dispatch(setPlans(plansAPI.data.data))
    }, [plansAPI.data])
    useEffect(() => {
        if (aiConnectAPI.error) {
            console.log(aiConnectAPI.error)
            setPyloader(false)
            setTimeout(() => {
                aiConnectAPI.request()
            }, 5000)
        }
        if (aiConnectAPI.data) {
            setPyloader(true)
            flowApi.request(token)
        }
    }, [aiConnectAPI.data, aiConnectAPI.error])
    useEffect(() => {
        if (flowApi.data) {
            dispatch(setAllProcess({ allProcess: flowApi.data.data }))
            setProcessFlows(flowApi.data.data)
        }

        if (flowApi.error) {
            console.log(flowApi.error)
        }
    }, [flowApi.data, flowApi.error])

    return pyloader ? (
        <div className="px-4 sm:px-6 lg:px-8 mt-2">
                        <Snackbar
                open={processValidationError.status}
                autoHideDuration={5000}
                onClose={() => {
                    setProcessValidationError({status: false, message: ''})
                }}
                message={processValidationError.message}
            />
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 text-xl">
                        Process Flows
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {toggleItemView
                            ? "A list of recent process flows you created!"
                            : "A list of recent process flow you created."}
                    </p>
                </div>

                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        className="rounded-full bg-red-500 p-2 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleAddProcessFlow}
                    >
                        <PlusIcon className="h-7 w-7" aria-hidden="true" />
                    </button>
                    <button
                        type="button"
                        className="rounded-full bg-red-500 m-4 p-2 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                            setToggleItemView(!toggleItemView)
                        }}
                    >
                        {!toggleItemView ? (
                            <ListBulletIcon
                                className="h-7 w-7"
                                aria-hidden="true"
                            />
                        ) : (
                            <Squares2X2Icon
                                className="h-7 w-7"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </div>
            </div>
            <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2  sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        {flowApi.loading ? (
                            <ProgressBar />
                        ) : toggleItemView ? (
                            <ProcessflowsTable
                                setProcessFlows={setProcessFlows}
                                processFlows={processFlows}
                                itemLength={10}
                            />
                        ) : (
                            <ProcessflowsCardList
                                itemLength={10}
                                setProcessFlows={setProcessFlows}
                                processFlows={processFlows}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="loader"></div>
    )

    // return (
    //     <div className="flex flex-wrap">
    //       {processFlows.map((item, index) => (
    //           <ItemCard data={item} index={index}/>
    //       ))}
    //     </div>
    //   );
}
