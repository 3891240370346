export const ItemTypes = {
    BOX: "box",
}
type inputFields = {
    title: string | null,
    field1: string,
    field2: string,
    field3: string,
    field4: string,
    field5: string | null,
    field6: string | null,
    field7: string
  };
export const MongoDBTitles : inputFields = {
    title: "MongoDB Connection",
    field1: "Database Name",
    field2: "Collection Name",
    field3: "Table Name",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
}

export const MongoDBPlaceHolders : inputFields= {
    title: null,
    field1: "test-database",
    field2: "sample-restaurant",
    field3: "restaurants",
    field4: "",
    field5: null,
    field6: null,
    field7: ""
};

export const MongoDBKeys: inputFields = {
    title: null,
    field1 : "DB",
    field2: "Collection",
    field3: "TableName",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
};

//----------------------------------------
export const MYSQLTitles: inputFields = {
    title: "MySQL Connection",
    field1: "Host",
    field2: "Database Name",
    field3: "Table Name",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
}

export const MYSQLPlaceHolders: inputFields = {
    title: null,
    field1: "localhost",
    field2: "database-name",
    field3: "AdventureWorks2019",
    field4: "",
    field5: null,
    field6: null,
    field7: ""
};

export const MYSQLKeys: inputFields = {
    title: null,
    field1: "Host",
    field2: "DatabaseName",
    field3: "TableName",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
};


//----------------------------------------
export const MSSQLTitles: inputFields = {
    title: "Microsft SQL Connection",
    field1: "Host",
    field2: "Database Name",
    field3: "Table Name",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
}

export const MSSQLPlaceHolders: inputFields = {
    title: null,
    field1: "localhost",
    field2: "database-name",
    field3: "AdventureWorks2019",
    field4: "",
    field5: null,
    field6: null,
    field7: ""
};

export const MSSQLKeys: inputFields = {
    title: null,
    field1: "Server",
    field2: "DatabaseName",
    field3: "TableName",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
};


//----------------------------------------
export const SNOWFLAKETitles: inputFields = {
    title: "SnokeFlake Connection",
    field1: "Account",
    field2: "Warehouse",
    field3: "Database name",
    field4: "Schema name",
    field5: "Table name",
    field6: "User",
    field7: "Password"
}

export const SNOWFLAKEPlaceHolders: inputFields = {
    title: null,
    field1: "account-name",
    field2: "WAREHOUSE_NAME",
    field3: "SNOWFLAKE_SAMPLE_DATA",
    field4: "TPCH_SF10",
    field5: "NATION",
    field6: "",
    field7: ""
};

export const SNOWFLAKEKeys: inputFields = {
    title: null,
    field1: "Account",
    field2: "Warehouse",
    field3: "DatabaseName",
    field4: "SchemaName",
    field5: "TableName",
    field6: "User",
    field7: "Password"
};


//------------------------------------------------

export const MongoDBTitlesLocal : inputFields = {
    title: "MongoDB Connection",
    field1: "Host",
    field2: "Port",
    field3: "Collection Name",
    field4: "Table Name",
    field5: "User",
    field6: null,
    field7: "Password",

}

export const MongoDBPlaceHoldersLocal : inputFields= {
    title: null,
    field1: "localhost",
    field2: "1234",
    field3: "db",
    field4: "my-table",
    field5: "",
    field6: null,
    field7: ""
};

export const MongoDBKeysLocal: inputFields = {
    title: null,
    field1 : "Host",
    field2 : "Port",
    field3: "Collection",
    field4: "TableName",
    field5: "User",
    field6: null,
    field7: "Password"
};

//------------------------------------------------------

export const PostgresTitles: inputFields = {
    title: "Postgres Connection",
    field1: "Host",
    field2: "Database Name",
    field3: "Table Name",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
}

export const PostgresPlaceHolders: inputFields = {
    title: null,
    field1: "localhost",
    field2: "database-name",
    field3: "AdventureWorks2019",
    field4: "",
    field5: null,
    field6: null,
    field7: ""
};

export const PostgresKeys: inputFields = {
    title: null,
    field1: "Host",
    field2: "DatabaseName",
    field3: "TableName",
    field4: "User",
    field5: null,
    field6: null,
    field7: "Password"
};
