import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material"
import { setShowDownloadDialog } from "../../features/flowSlice"
import useAppDispatch from "../../hooks/useAppDispatch"
import { useEffect, useState } from "react"
import { downloadResultAPI } from "../../api/utits"
import useAppSelector from "../../hooks/useAppSelector"

interface requestData {
    nodeId: string
    userId: string
}

interface Props {
    dialogOpen: boolean
    data: requestData
}

const DownloadDialog = (props: Props) => {
    const dispatch = useAppDispatch()
    const token = useAppSelector((state) => state.auth.token)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('');
    const showDownloadDialog = useAppSelector(state => state.processReducer.showDownloadDialog);
    const [downloadUrl, setDownloadUrl] = useState<string>("")

    useEffect(()=>{
        setIsLoading(false);
        setDownloadUrl('');
        setError('');

    },[showDownloadDialog])

    const getDownloadLink = async (type: string) => {
        try{
            if (token) {
                setIsLoading(true)
                const response = await downloadResultAPI(token, {
                    nodeId: props.data.nodeId,
                    userId: props.data.userId,
                    type,
                })
                if (response.status == 200)
                    {
                        setIsLoading(false);
                        setDownloadUrl(`${process.env.REACT_APP_API_URL_V1}/utils/download?nodeId=${props.data.nodeId}&userId=${props.data.userId}&type=${type}`)
                    }
                    else
                    {
                        setError('Something went wrong');
                    }
            }
        }
        catch(e)
        {
            setError('Something went wrong');
        }

    }


    useEffect(() => {}, [])

    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => {
                dispatch(setShowDownloadDialog(false))
            }}
        >
            <DialogTitle id="download-title">
                {"Download Response?"}
            </DialogTitle>
            <DialogContent>
                {!isLoading && <Box
                    sx={{ width: 1, display: "flex", flexDirection: "column" }}
                >
                    <button onClick={()=>{window.onbeforeunload = null; getDownloadLink('csv')}} className="mb-2 rounded bg-red-400 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500">
                        CSV
                    </button>
                    <button onClick={()=>{window.onbeforeunload = null; getDownloadLink('xlsx')}} className="rounded bg-red-400 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500">
                        XLSX
                    </button>
                    {downloadUrl && <a href={downloadUrl} download ><p style={{textDecoration: 'underline', color: 'blue'}}>Click Here to download</p></a>}
                </Box>}
                {isLoading && <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>}
                {error && <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <p>{error}</p>
                </Box>}
            </DialogContent>
            <DialogActions>
                <button
                    onClick={() => {
                        dispatch(setShowDownloadDialog(false))
                    }}
                >
                    Close
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default DownloadDialog
