import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ProcessFlowType } from "../../types/flow"
import useApi from "../../hooks/useApi"
import flow from "../../api/flow"
import useAppSelector from "../../hooks/useAppSelector"
import ProcessflowsTableRow from "./ProcessFlowTableRow"
import { getRecentItems } from "./recentItemFilter"

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

function formatDateToCustomFormat(inputDate: string): string {
    const inputDateTransformed = new Date(inputDate)

    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    }

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        inputDateTransformed
    )

    return formattedDate
}

interface ProcessflowsTableProps {
    processFlows: ProcessFlowType[]
    setProcessFlows: any
    itemLength: number
}

interface ProcessFlow extends ProcessFlowType {
    edit: boolean
}

export default function ProcessflowsTable({
    processFlows,
    setProcessFlows,
    itemLength
}: ProcessflowsTableProps) {
    const navigate = useNavigate()
    const token = useAppSelector((state) => state.auth.token)

    const updateFlowApi = useApi(flow.updateFlow)

    let recentItems = getRecentItems(processFlows, itemLength);
    
    const handleStartEdit = (processFlow: ProcessFlow) => {
        setProcessFlows((prev: ProcessFlowType[]) =>
            prev.map((p: ProcessFlowType) => {
                if (p.id === processFlow.id) {
                    return {
                        ...p,
                        edit: true,
                    }
                }
                return p
            })
        )
    }
    const handleEndEdit = (processFlow: ProcessFlow) => {
        setProcessFlows((prev: ProcessFlowType[]) =>
            prev.map((p) => {
                if (p.id === processFlow.id) {
                    return {
                        ...p,
                        edit: false,
                    }
                }
                return p
            })
        )
        const flow = processFlows.find((f) => f.id === processFlow.id)
        updateFlowApi.request(token, flow)
    }

    const handleDescriptionChange = (
        processFlow: ProcessFlow,
        value: string
    ) => {
        setProcessFlows((prev: ProcessFlowType[]) =>
            prev.map((p) => {
                if (p.id === processFlow.id) {
                    return {
                        ...p,
                        description: value,
                    }
                }
                return p
            })
        )
    }

    // useEffect(() => {
    //     if (processFlows.length === 0) {
    //         setProcessFlows(
    //             processFlows.map((flow: ProcessFlowType): ProcessFlow => {
    //                 return {
    //                     ...flow,
    //                     edit: false,
    //                 }
    //             })
    //         )
    //     }
    // }, [processFlows])

    useEffect(() => {
       
    }, [updateFlowApi.data])
    return (
        <table className="min-w-full divide-y divide-gray-300 border">
            <thead>
                <tr>
                    <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                        Name
                    </th>
                    <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                        Created
                    </th>
                    <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                        Modified
                    </th>
                    <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                        Description
                    </th>
                    <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                        <span className="sr-only">Select</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {recentItems.map((processFlow: ProcessFlowType, i: number) => (
                    
                   processFlow.isSaved && (
                    <ProcessflowsTableRow
                        key={i}  // Make sure to add a unique key to each rendered element
                        processFlow={processFlow}
                        index={i}
                        setProcessFlows={setProcessFlows}
                    />
                )
                ))}
                {recentItems.length === 0 ? (
                    <tr>
                        <td
                            className={
                                "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                            }
                        >
                            <div className="font-xs text-gray-400">
                                No Process Flows Yet
                            </div>
                        </td>
                    </tr>
                ) : (
                    <></>
                )}
            </tbody>
        </table>
    )
}
