import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { BrowserRouter, HashRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./app/store"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { ReactFlowProvider } from "reactflow"
import { IPCProvider } from "./context/ipc"


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

console.log(process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID)
root.render(
    <Provider store={store}>
        <HashRouter>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID|| ''}>
                <DndProvider backend={HTML5Backend}>
                    <ReactFlowProvider>
                        <IPCProvider>
                            <App />
                        </IPCProvider>
                    </ReactFlowProvider>
                </DndProvider>
            </GoogleOAuthProvider>
        </HashRouter>
    </Provider>
)
