// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 9px solid #fff8f8;
    border-top: 9px solid rgb(225, 69, 69);
    border-radius: 50%;
    animation: spinner 600ms linear infinite;
    margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/ui-components/common/LoadingSpinner.css"],"names":[],"mappings":"AAAA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,sCAAsC;IACtC,kBAAkB;IAClB,wCAAwC;IACxC,cAAc;AAClB","sourcesContent":["@keyframes spinner {\r\n    0% {\r\n        transform: rotate(0deg);\r\n    }\r\n    100% {\r\n        transform: rotate(360deg);\r\n    }\r\n}\r\n\r\n.loading-spinner {\r\n    width: 50px;\r\n    height: 50px;\r\n    border: 9px solid #fff8f8;\r\n    border-top: 9px solid rgb(225, 69, 69);\r\n    border-radius: 50%;\r\n    animation: spinner 600ms linear infinite;\r\n    margin: 0 auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
