import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ProcessFlowType } from "../../types/flow"
import useApi from "../../hooks/useApi"
import flow from "../../api/flow"
import useAppSelector from "../../hooks/useAppSelector"
import ItemCard from "./ItemCard"
import { generateRandomId } from "../../utils/misc"
import { getRecentItems } from "./recentItemFilter"


interface ProcessflowsCardListProps {
    processFlows: ProcessFlowType[]
    setProcessFlows: any,
    itemLength: number
}

interface ProcessFlow extends ProcessFlowType {
    edit: boolean
}

export default function ProcessflowsCardList({
    processFlows,
    setProcessFlows,
    itemLength
}: ProcessflowsCardListProps) {
    const navigate = useNavigate()
    const token = useAppSelector((state) => state.auth.token)
    let recentItems = getRecentItems(processFlows, itemLength);
    
    console.log(recentItems)
    useEffect(()=>{
        
    },[])

    return (
        <div className="flex flex-wrap">
          {recentItems && recentItems.map((item, index) => (
              item.isSaved ? <ItemCard key={generateRandomId(16)} processFlow={item} data={item} index={index}/> : null
          ))}
        </div>
      );
}
