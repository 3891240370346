import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import useApi from "../../hooks/useApi"
import flow from "../../api/flow"
import predict from "../../api/predict"
import useAppSelector from "../../hooks/useAppSelector"
import { ProcessFlowType } from "../../types/flow"
import './../../style/misc.css'
import ProgressBar from "../../ui-components/common/ProgressBar"
import ProcessflowsTable from "../../ui-components/cardandlists/ProcessflowsTable"
import { PlusIcon } from "@heroicons/react/20/solid"
import { Squares2X2Icon } from "@heroicons/react/20/solid"
import { ListBulletIcon } from "@heroicons/react/20/solid"
import ProcessflowsCardList from "../../ui-components/cardandlists/ProcessflowsCardList"
import useAppDispatch from "../../hooks/useAppDispatch"
import { setAllProcess } from "../../features/flowSlice"
interface Props {}

export default function DashboardPage({}: Props) {
    const token = useAppSelector((state) => state.auth.token)
    const dispatch = useAppDispatch();
    const [pyloader, setPyloader] = useState(false);
    const [toggleItemView, setToggleItemView] = useState(false)
    const navigate = useNavigate()

    const [processFlows, setProcessFlows] = useState<ProcessFlowType[]>([])
    const [processFlowsFiltered, setProcessFlowsFiltered] = useState<ProcessFlowType[]>([])
    const handleAddProcessFlow = () => {
        navigate("/canvas")
    }
    const flowApi = useApi(flow.getAllUserFlows)
    const aiConnectAPI = useApi(predict.aiConnectAPI)
    useEffect(() => {
        setPyloader(true)
        aiConnectAPI.request();
    }, [])

    useEffect(()=>{
        if (aiConnectAPI.error)
        {
            console.log(aiConnectAPI.error)
            setPyloader(false)
            setTimeout(()=>{
                aiConnectAPI.request();
            },5000)
        }
        if (aiConnectAPI.data)
        {
            setPyloader(true)
            flowApi.request(token)
        }
    }, [aiConnectAPI.data, aiConnectAPI.error])
    useEffect(() => {
        if (flowApi.data) {
            setProcessFlows(flowApi.data.data)
            dispatch(setAllProcess({allProcess:flowApi.data.data}))
            setProcessFlowsFiltered(flowApi.data.data)
        }

        if (flowApi.error) {
            console.log(flowApi.error)
        }
    }, [flowApi.data, flowApi.error])
    const filterProcessFlow = (event: React.ChangeEvent<HTMLInputElement>)=> {
        const inputValue = event.target.value;
        const f = processFlows.filter(a => a.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) || a.description.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))
        console.log(processFlowsFiltered)
        console.log(f)
        console.log(inputValue);
        setProcessFlowsFiltered(f);
    }
    return (
        pyloader ? (<div className="px-4 sm:px-6 lg:px-8 mt-2">
            
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 text-xl">
                        Process Flows
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {toggleItemView ? "A list of all process flows you created!": "A list of all process flow you created."}
                    </p>
                </div>
                <div className="sm:flex-auto relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input onChange={filterProcessFlow} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 focus:outline-none rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500" placeholder="Search Process..." required />
        
    </div>   
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        className="rounded-full bg-red-500 p-2 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleAddProcessFlow}
                    >
                        <PlusIcon className="h-7 w-7" aria-hidden="true" />
                    </button>
                    <button
                        type="button"
                        className="rounded-full bg-red-500 m-4 p-2 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={()=>{setToggleItemView(!toggleItemView)}}
                    >
                        {!toggleItemView ? (<ListBulletIcon className="h-7 w-7" aria-hidden="true" />): <Squares2X2Icon className="h-7 w-7" aria-hidden="true" />}
                        
                    </button>
                </div>
            </div>
            <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2  sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        {flowApi.loading ? (
                            <ProgressBar />
                        ) : 
                            toggleItemView ? <ProcessflowsTable
                                setProcessFlows={setProcessFlowsFiltered}
                                processFlows={processFlowsFiltered}
                                itemLength={0}
                            /> :
                            <ProcessflowsCardList itemLength={0} setProcessFlows={setProcessFlowsFiltered}
                                 processFlows={processFlowsFiltered} />

                        
                        }
                    </div>
                </div>
            </div>
        </div>) : (<div className="loader"></div>)
    )
   
    // return (
    //     <div className="flex flex-wrap">
    //       {processFlows.map((item, index) => (
    //           <ItemCard data={item} index={index}/>
    //       ))}
    //     </div>
    //   );
}
