import { PropsWithChildren, useEffect, useState } from "react"
import { Node, Edge } from "reactflow"
import "./CanvasNodeInput.css"
import PromptInput from "../../ui-components/canvas/input/PromptInput"
import DocumentInput from "../../ui-components/canvas/input/DocumentInput"
import PredictiveInput from "../../ui-components/canvas/input/PredictiveInput"
import DatabaseInput from "../../ui-components/canvas/input/DatabaseInput"
import { useDispatch } from "react-redux"
import { setSelectedNode } from "../../features/flowSlice"
import useAppSelector from "../../hooks/useAppSelector"

interface CanvasNodeInputProps extends PropsWithChildren {
    nodes: Node<any>[]
    setNodes: any
    edges: Edge<any>[]
    setEdges: any
    setDisabled: any
}

interface CSVData {
    fileName: string
    fileType: string
    fileData: string
    sheetName: string
}

interface DatabasConfig {
    value: object
    conn: object
    dbtype: string
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
    nodes,
    setNodes,
    edges,
    setEdges,
    setDisabled,
}: CanvasNodeInputProps) {
    // I can use useReactFlow because this is wrapped inside the React flow component
    const [selectedNode, setSelectedNodeState] = useState<Node<any> | null>(
        null
    )
    const dispatch = useDispatch()

    const handlePromptSave = (content: string, promptName: string) => {
        setNodes((nds: Node<any>[]) =>
            nds.map((nd) => {
                if (nd.id === selectedNode?.id) {
                    const newNd = { ...nd }
                    newNd.data = { ...newNd.data, content, name: promptName }
                    return newNd
                }
                return nd
            })
        )
    }
    const handlePromptFocus = () => {
        setNodes((nds: Node<any>[]) =>
            nds.map((nd) => {
                if (nd.id === selectedNode?.id) {
                    return {
                        ...nd,
                        data: {
                            ...nd.data,
                            isEditing: true,
                        },
                    }
                }
                return nd
            })
        )
        edges.map((e) => {
            //e.selected = false;
        })
        setEdges(edges)
    }
    const handlePromptAbortFocus = () => {
        console.log("Focus out")
        setNodes((nds: Node<any>[]) =>
            nds.map((nd) => {
                if (nd.id === selectedNode?.id) {
                    return {
                        ...nd,
                        data: {
                            ...nd.data,
                            isEditing: false,
                        },
                    }
                }
                return nd
            })
        )
    }

    const handleDocumentUpload = (csvData: CSVData) => {
        setNodes((nds: Node<any>[]) =>
            nds.map((nd) => {
                if (nd.id === selectedNode?.id) {
                    const newNd = { ...nd }
                    newNd.data = { ...csvData }
                    return newNd
                }
                return nd
            })
        )
    }

    const handlePredictiveUpload = (
        referenceFileData: CSVData,
        fileToPredictData: CSVData
    ) => {
        console.log(referenceFileData, fileToPredictData)
        setNodes((nds: Node<any>[]) =>
            nds.map((nd) => {
                if (nd.id === selectedNode?.id) {
                    const newNd = { ...nd }
                    newNd.data = {
                        ...newNd.data,
                        referenceFile: referenceFileData,
                        fileToPredict: fileToPredictData,
                    }
                    return newNd
                }
                return nd
            })
        )
        console.log("done")
    }

    const handleDatabaseSave = (databasConfig: DatabasConfig) => {
        setNodes((nds: Node<any>[]) =>
            nds.map((nd) => {
                if (nd.id === selectedNode?.id) {
                    const newNd = { ...nd }
                    newNd.data = {
                        ...newNd.data,
                        value: databasConfig?.value,
                        conn: databasConfig?.conn,
                        dbtype: databasConfig?.dbtype,
                    }
                    return newNd
                }
                return nd
            })
        )
    }
    useEffect(() => {
        //console.log("useEffect1")
        const selected = nodes.find((nd) => nd.selected)

        if (selected?.id === selectedNode?.id) return
        if (selected) {
            setSelectedNodeState(selected)
        } else {
            setSelectedNodeState(null)
        }
    }, [nodes])

    useEffect(() => {
        dispatch(setSelectedNode(selectedNode))
        if (!selectedNode) {
            return setNodes((nds: Node[]) => {
                return nds.map((nd) => {
                    return { ...nd, selected: false }
                })
            })
        }
    }, [selectedNode])

    return (
        <div
            className={`node-input-container ${
                selectedNode?.type !== "database" ? "h-[450px]" : "h-[540px]"
            } shadow-sm ${
                selectedNode ? "" : "hidden-container"
            }`}
        >
            {selectedNode?.type === "prompt" && (
                <PromptInput
                    setDisabled={setDisabled}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNodeState}
                    handlePromptSave={handlePromptSave}
                    handlePromptAbortFocus={handlePromptAbortFocus}
                    handlePromptFocus={handlePromptFocus}
                />
            )}
            {selectedNode?.type === "document" && (
                <DocumentInput
                    selectedNode={selectedNode}
                    setDisabled={setDisabled}
                    setSelectedNode={setSelectedNodeState}
                    handleDocumentUpload={handleDocumentUpload}
                />
            )}
            {selectedNode?.type === "predictive" && (
                <PredictiveInput
                    setDisabled={setDisabled}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNodeState}
                    handlePredictiveUpload={handlePredictiveUpload}
                />
            )}
            {selectedNode?.type === "database" && (
                <DatabaseInput
                    setDisabled={setDisabled}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNodeState}
                    handleDatabaseAbortFocus={handlePromptAbortFocus}
                    handleDatabaseFocus={handlePromptFocus}
                    handleDatabaseSave={handleDatabaseSave}
                />
            )}
        </div>
    )
}
