// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-text-header {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    top: 0; /* Position under the icon */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.tooltip-text-tooltip {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 11px;
    border-radius: 4px;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    top: 120%; /* Position under the icon */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
    text-wrap: nowrap;
}

.custom-tooltip:hover .tooltip-text-header,
.custom-tooltip:hover .tooltip-text-tooltip {
    visibility: visible;
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/views/canvas/CanvasHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,MAAM,EAAE,4BAA4B;IACpC,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,SAAS,EAAE,4BAA4B;IACvC,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;;IAEI,mBAAmB;IACnB,UAAU;AACd","sourcesContent":[".tooltip-text-header {\n    visibility: hidden;\n    background-color: #333;\n    color: #fff;\n    text-align: center;\n    border-radius: 4px;\n    padding: 4px 8px;\n    position: absolute;\n    z-index: 1;\n    top: 0; /* Position under the icon */\n    left: 50%;\n    transform: translateX(-50%);\n    opacity: 0;\n    transition: opacity 0.2s;\n}\n\n.tooltip-text-tooltip {\n    visibility: hidden;\n    background-color: #333;\n    color: #fff;\n    text-align: center;\n    font-size: 11px;\n    border-radius: 4px;\n    padding: 4px 8px;\n    position: absolute;\n    z-index: 1;\n    top: 120%; /* Position under the icon */\n    left: 50%;\n    transform: translateX(-50%);\n    opacity: 0;\n    transition: opacity 0.2s;\n    text-wrap: nowrap;\n}\n\n.custom-tooltip:hover .tooltip-text-header,\n.custom-tooltip:hover .tooltip-text-tooltip {\n    visibility: visible;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
