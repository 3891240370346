import { Fragment, useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ProcessFlowType } from "../../types/flow"
import useAppSelector from "../../hooks/useAppSelector"
import useApi from "../../hooks/useApi"
import flow from "../../api/flow"
import { Listbox, Transition } from "@headlessui/react"
import OptionsButton from "./OptionsButton"
import DeleteProcessFlowModal from "../processflows/DeleteFlowModal"
import './../../style/flow-list.css'
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ")
}

function formatDateToCustomFormat(inputDate: string): string {
    const inputDateTransformed = new Date(inputDate)

    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    }

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        inputDateTransformed
    )

    return formattedDate
}

interface ProcessflowsTableRowProps {
    processFlow: ProcessFlowType
    setProcessFlows: any
    index: number
}

export default function ProcessflowsTableRow({
    processFlow,
    setProcessFlows,
    index,
}: ProcessflowsTableRowProps) {
    const navigate = useNavigate()
    const token = useAppSelector((state) => state.auth.token)

    const [isEdit, setIsEdit] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const updateFlowApi = useApi(flow.updateFlow)
    const deleteFlowApi = useApi(flow.deleteFlow)

    const handleEndEdit = (processFlow: ProcessFlowType) => {
        setIsEdit(false)
        updateFlowApi.request(token, processFlow)
    }

    const handleDeleteFlow = () => {
        deleteFlowApi.request(token, processFlow.id)
    }

    const handleDescriptionChange = (
        processFlow: ProcessFlowType,
        value: string
    ) => {
        setProcessFlows((prev: ProcessFlowType[]) =>
            prev.map((p) => {
                if (p.id === processFlow.id) {
                    return {
                        ...p,
                        description: value,
                    }
                }
                return p
            })
        )
    }

    useEffect(() => {
        if (deleteFlowApi.data) {
            window.location.reload()
        }
        
    }, [deleteFlowApi.data])

    return (
        <tr className="flow-row-style">
            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-transparent",
                    "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                )}
            >
                <div className="font-medium text-gray-900">
                <Link to={`/canvas/${processFlow.id}`}>{processFlow.name}</Link>
                </div>
            </td>
            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
            >
                {formatDateToCustomFormat(processFlow.createdAt)}
            </td>
            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
            >
                {formatDateToCustomFormat(processFlow.updatedAt)}
            </td>
            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-xs text-gray-500 lg:table-cell w-[30%]"
                )}
            >
                {isEdit ? (
                    <div className=" flex flex-col gap-2">
                        <textarea
                            value={processFlow.description}
                            maxLength={210}
                            className="border-2 p-1 border-gray-100 outline-gray-200 w-[100%] resize-none"
                            onChange={(e) =>
                                handleDescriptionChange(
                                    processFlow,
                                    e.target.value
                                )
                            }
                        />

                        <button
                            onClick={(e) => {
                                handleEndEdit(processFlow)
                            }}
                            className="bg-gray-400 text-white rounded-md w-[50px] ml-2 hover:bg-gray-500"
                        >
                            Done
                        </button>
                    </div>
                ) : (
                    <div className="flex gap-2 justify-between items-center">
                        <p className="max-w-[70%]">{processFlow.description}</p>
                    </div>
                )}
            </td>

            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-transparent",
                    "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                )}
            >
                {/* <button
                    type="button"
                    onClick={() => navigate(`/canvas/${processFlow.id}`)}
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                >
                    Open
                </button> */}
                <OptionsButton
                    descriptionPrompt={false}
                    processFlow={processFlow}
                    handleEditDescription={() => setIsEdit(true)}
                    setModalOpen={setModalOpen}
                />
            </td>

            {/* <td
                className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-1 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
            > */}
            {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 cursor-pointer hover:text-gray-600 text-gray-400"
                    onClick={(e) => {
                        setIsEdit(true)
                    }}
                >
                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                </svg> */}
            {/* </td> */}
            <DeleteProcessFlowModal
                open={modalOpen}
                setOpen={setModalOpen}
                handleDeleteFlow={handleDeleteFlow}
            />
        </tr>
    )
}
