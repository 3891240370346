import { AxiosResponse } from "axios"
import { apiClient } from "./client"
import APIResponse from "../types/response"
import { ProcessFlowType } from "../types/flow"

const getAllUserFlows = (
    token: string
): Promise<AxiosResponse<APIResponse<ProcessFlowType[]>>> =>
    apiClient.get("/v1/flow", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

const getAllRecentUserFlows = (
        token: string
    ): Promise<AxiosResponse<APIResponse<ProcessFlowType[]>>> =>
        apiClient.get("/v1/flow/recent", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

const getOneFlow = (
    token: string,
    flowId: string
): Promise<AxiosResponse<APIResponse<ProcessFlowType>>> =>
    apiClient.get(`/v1/flow/${flowId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

export const getOneFlowRequest = async (flowId: string, token: string) => {
    return await apiClient.get(`/v1/flow/${flowId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

}

const createFlow = (
    token: string,
    flow: any
): Promise<AxiosResponse<APIResponse<ProcessFlowType>>> =>
    apiClient.post(`/v1/flow/`, flow, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

const updateFlow = (
    token: string,
    flow: any
): Promise<AxiosResponse<APIResponse<ProcessFlowType>>> =>
    apiClient.patch(`/v1/flow/${flow.id}`, flow, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

const deleteFlow = (
    token: string,
    flowId: string
): Promise<AxiosResponse<APIResponse<null>>> =>
    apiClient.delete(`/v1/flow/${flowId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

// const deleteFlow = (id: string) => apiClient.delete(`/apikey/${id}`)

export default {
    getAllUserFlows,
    getAllRecentUserFlows,
    getOneFlow,
    updateFlow,
    createFlow,
    deleteFlow,
    
}
